import { TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

export const TableContainerStyled = withStyles({
  root: {
    width: 'calc(100% - 60px)',
    padding: '30px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    gap: '25px'
  },
})(Paper);

export const TableHeadStyled = withStyles({
  root: {
    backgroundColor: '#F1F2F599',
  },
})(TableHead);

export const Cell = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
  },
})(TableCell);

export const HeadCell = withStyles({
  root: {
    fontWeight: 600,
    borderBottom: 'none',
  },
})(Cell);

export const HeadRowStyled = withStyles({
  root: {
    '& th:first-child': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    '& th:last-child': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
})(TableRow);

export const PaginationStyled = withStyles({
  root: {
    width: 'fit-content',

    '& .MuiPagination-ul li:last-child button': {
      marginLeft: '13px',
    },
    '& .MuiPagination-ul li:first-child button': {
      marginRight: '13px',
    },

    '& .MuiPagination-ul li:last-child button::before': {
      content: "'Next'",
      marginRight: '8px',
    },
    '& .MuiPagination-ul li:first-child button::after': {
      content: "'Back'",
      marginLeft: '8px',
    },

    '& ul > li': {
      '& .MuiPaginationItem-page.Mui-selected': {
        color: '#FFFFFF',
        backgroundColor: '#2596BE',
        borderColor: '#2596BE',
      },
      '& .MuiPaginationItem-outlined': {
        border: '1px solid #E0E0E0',
      },
      '& button': {
        height: '22px',
        width: 'fit-content',
        minWidth: '25px',
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        color: '#000000',
      },
    },
  },
})(Pagination);