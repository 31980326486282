import { styled } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

export const StyledInputContainer = styled("div")({
  fontFamily: "Source Sans Pro",
  cursor: "default",
  marginBottom: "1rem",
  "& h6": {
    marginBottom: "5px"
  }
});

export const StyledErrorLabel = styled(Typography)(
  (theme: { className: string }) => ({
    visibility: theme.className === "visible" ? "visible" : "hidden",
    color: "#FF1D1D"
  })
);

export const StyledIconButton = styled(IconButton)(
  (theme: { className: string }) => ({
    visibility: theme.className === "hidden" ? "hidden" : "visible"
  })
);

export const StyledButton = styled(Button)({
  backgroundColor: "#2596BE",
  color: "#ffff",
  paddingLeft: "3.5rem",
  paddingRight: "3.5rem",
  marginTop: ".2rem",
  marginBottom: ".5rem",
  "&:hover": {
    backgroundColor: "rgba(37, 150, 190, .8)"
  }
});

export const StyledErrorOnLimit = styled("div")(
  (theme: { className: string }) => ({
    visibility: theme.className === "visible" ? "visible" : "hidden",
    color: "#FF0000",
    display: "flex",
    marginTop: "2rem",
    "& div": {
      lineHeight: 3
    }
  })
);
