import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const PowerIcon = () => {
  return (
    <PowerSvgIcon style={{ color: 'inherit' }} viewBox='0 0 20 20'>
      <path d="M9.50021 11.6368C9.2181 11.6368 8.94755 11.5248 8.74807 11.3253C8.54859 11.1258 8.43652 10.8553 8.43652 10.5732V2.06368C8.43652 1.78158 8.54859 1.51103 8.74807 1.31155C8.94755 1.11207 9.2181 1 9.50021 1C9.78231 1 10.0529 1.11207 10.2523 1.31155C10.4518 1.51103 10.5639 1.78158 10.5639 2.06368V10.5732C10.5639 10.8553 10.4518 11.1258 10.2523 11.3253C10.0529 11.5248 9.78231 11.6368 9.50021 11.6368Z" />
      <path d="M9.5 19.0826C7.69002 19.0806 5.92783 18.5015 4.46947 17.4295C3.01112 16.3575 1.93257 14.8484 1.39053 13.1215C0.8485 11.3946 0.871224 9.53981 1.4554 7.82669C2.03958 6.11358 3.15478 4.63136 4.63897 3.59539C4.75304 3.50696 4.88393 3.44267 5.02364 3.40644C5.16336 3.37021 5.30899 3.36279 5.45167 3.38464C5.59434 3.40649 5.73108 3.45715 5.85355 3.53353C5.97602 3.60992 6.08167 3.71043 6.16405 3.82895C6.24643 3.94747 6.30384 4.08151 6.33276 4.22292C6.36168 4.36433 6.36152 4.51015 6.33229 4.6515C6.30305 4.79285 6.24536 4.92677 6.16271 5.0451C6.08007 5.16344 5.9742 5.26372 5.85157 5.33983C4.73689 6.11647 3.89913 7.22855 3.46019 8.51424C3.02125 9.79994 3.00404 11.1922 3.41105 12.4883C3.81807 13.7845 4.62807 14.9169 5.72321 15.7209C6.81834 16.5248 8.14145 16.9584 9.5 16.9584C10.8586 16.9584 12.1817 16.5248 13.2768 15.7209C14.3719 14.9169 15.1819 13.7845 15.589 12.4883C15.996 11.1922 15.9788 9.79994 15.5398 8.51424C15.1009 7.22855 14.2631 6.11647 13.1484 5.33983C12.933 5.17286 12.7899 4.92958 12.7487 4.66018C12.7074 4.39078 12.7712 4.11582 12.9267 3.89204C13.0823 3.66826 13.3178 3.51272 13.5847 3.45752C13.8516 3.40232 14.1295 3.45167 14.361 3.59539C15.8452 4.63136 16.9604 6.11358 17.5446 7.82669C18.1288 9.53981 18.1515 11.3946 17.6095 13.1215C17.0674 14.8484 15.9889 16.3575 14.5305 17.4295C13.0722 18.5015 11.31 19.0806 9.5 19.0826Z" />
    </PowerSvgIcon>
  );
}

const PowerSvgIcon = withStyles({
  root: {
    color: "#1A1A1A",
  },
})(SvgIcon);