import React, { ChangeEvent, Component } from "react";
import {
  SecurityTextfieldsWrapper,
  SecurityWrapper,
  ButtonsWrapper,
  ResetButton,
  SaveButton,
  TitleMedium,
  TextFieldStyled,
  FlexColumnWrapperMediumWithErrorSpace,
  TextFieldErrorTitle
} from "../../styles/styledElements";
import { SecurityProps } from "./types/securityProps";
import { configJSON } from "../../../../ConsultantDashboardController.web";
import { validateStringOnAtLeastOneChar } from "../../../../utils/validateStringOnAtLeastOneChar";

export class Security extends Component<SecurityProps> {
  state = {
    old: "",
    new: "",
    confirm: "",
    oldPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: ""
  };

  constructor(props: SecurityProps) {
    super(props);

    this.resetFields = this.resetFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
  }

  resetFields() {
    this.setState({
      old: "",
      new: "",
      confirm: "",
      oldPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: ""
    });
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  saveHandler() {
    const upper = validateStringOnAtLeastOneChar(this.state.new, "upper");
    const lower = validateStringOnAtLeastOneChar(this.state.new, "lower");
    const digit = validateStringOnAtLeastOneChar(this.state.new, "digit");
    const special = validateStringOnAtLeastOneChar(this.state.new, "special");
    const oldPasswordEmpty = this.state.old.trim() === "";

    if (
      oldPasswordEmpty ||
      this.state.new !== this.state.confirm ||
      this.state.new.length < 8 ||
      this.state.new.length > 15 ||
      this.state.confirm === "" ||
      !upper ||
      !lower ||
      !digit ||
      !special
    ) {
      if (oldPasswordEmpty) {
        this.setState({ oldPasswordError: "This field cannot be empty" });
      }

      if (this.state.confirm === "") {
        this.setState({ confirmPasswordError: "Please confirm your password" });
      }

      if (this.state.new !== this.state.confirm && this.state.confirm !== "") {
        this.setState({ confirmPasswordError: "Passwords do not match" });
      }

      if (this.state.new.length < 8) {
        this.setState({
          newPasswordError: "Password needs to be at least 8 characters long"
        });
      }

      if (this.state.new.length > 15) {
        this.setState({
          newPasswordError: "Password cannot be longer than 15 characters"
        });
      }

      if (!special) {
        this.setState({
          newPasswordError:
            "Password should contain at least one special character"
        });
      }

      if (!upper) {
        this.setState({
          newPasswordError:
            "Password should contain at least one upper case letter"
        });
      }

      if (!lower) {
        this.setState({
          newPasswordError:
            "Password should contain at least one lower case letter"
        });
      }

      if (!digit) {
        this.setState({
          newPasswordError: "Password should contain at least one digit"
        });
      }

      setTimeout(() => {
        this.setState({
          confirmPasswordError: "",
          newPasswordError: "",
          oldPasswordError: ""
        });
      }, 3000);
    } else {
      if (this.props.updatePassword) {
        this.props.updatePassword(this.state.old, this.state.new);
      }

      this.resetFields();
    }
  }

  render() {
    return (
      <SecurityWrapper>
        <SecurityTextfieldsWrapper>
          <FlexColumnWrapperMediumWithErrorSpace>
            <TitleMedium>{configJSON.oldPasswordTitle}</TitleMedium>
            <TextFieldStyled
              name="old"
              variant="outlined"
              placeholder={configJSON.oldPasswordPlaceholder}
              type="password"
              value={this.state.old}
              onChange={this.handleInputChange}
              error={Boolean(this.state.oldPasswordError)}
            />
            {this.state.oldPasswordError && (
              <TextFieldErrorTitle>
                {this.state.oldPasswordError}
              </TextFieldErrorTitle>
            )}
          </FlexColumnWrapperMediumWithErrorSpace>

          <FlexColumnWrapperMediumWithErrorSpace>
            <TitleMedium>{configJSON.newPassworsTitle}</TitleMedium>
            <TextFieldStyled
              inputProps={{ maxLength: 15 }}
              name="new"
              variant="outlined"
              placeholder={configJSON.newPasswordPlaceholder}
              type="password"
              value={this.state.new}
              onChange={this.handleInputChange}
              data-test-id="newPswdInput"
              id="newPswd"
              error={Boolean(this.state.newPasswordError)}
            />
            {this.state.newPasswordError && (
              <TextFieldErrorTitle>
                {this.state.newPasswordError}
              </TextFieldErrorTitle>
            )}
          </FlexColumnWrapperMediumWithErrorSpace>

          <FlexColumnWrapperMediumWithErrorSpace>
            <TitleMedium>{configJSON.confirmPasswordTitle}</TitleMedium>
            <TextFieldStyled
              inputProps={{ maxLength: 15 }}
              name="confirm"
              variant="outlined"
              placeholder={configJSON.confirmPasswordPlaceholder}
              type="password"
              value={this.state.confirm}
              onChange={this.handleInputChange}
              error={Boolean(this.state.confirmPasswordError)}
              data-test-id="confirmPswdInput"
              id="confirmPswdInput"
            />
            {this.state.confirmPasswordError && (
              <TextFieldErrorTitle>
                {this.state.confirmPasswordError}
              </TextFieldErrorTitle>
            )}
          </FlexColumnWrapperMediumWithErrorSpace>
        </SecurityTextfieldsWrapper>

        <ButtonsWrapper>
          <SaveButton onClick={this.saveHandler} id="save-btn">
            {configJSON.saveChangesButton}
          </SaveButton>
          <ResetButton variant="outlined" onClick={this.resetFields}>
            {configJSON.resetButtonTitle}
          </ResetButton>
        </ButtonsWrapper>
      </SecurityWrapper>
    );
  }
}
