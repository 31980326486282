import React, { PropsWithChildren } from 'react';
import { Box, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AvatarWithNameWrapperProps } from './avatarWithNameWrapperProps';

export const AvatarWithNameWrapper = ({ imgUrl, children }: PropsWithChildren<AvatarWithNameWrapperProps>) => {
  return (
    <Wrapper>
      <Avatar style={{ width: '25px', height: '25px' }} src={imgUrl} />
      {children}
    </Wrapper>
  );
};

const Wrapper = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
})(Box);
