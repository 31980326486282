import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const AddNewUserIcon = () => {
  return (
    <AddNewUserSvgIcon viewBox="0 0 17 14">
      <path d="M11 13.0001V11.6667C11 10.9595 10.719 10.2812 10.2189 9.78111C9.71884 9.28101 9.04057 9.00006 8.33332 9.00006H3.66666C2.95942 9.00006 2.28114 9.28101 1.78105 9.78111C1.28095 10.2812 1 10.9595 1 11.6667V13.0001" />
      <path d="M6.00016 6.33333C7.47292 6.33333 8.66682 5.13942 8.66682 3.66666C8.66682 2.19391 7.47292 1 6.00016 1C4.5274 1 3.3335 2.19391 3.3335 3.66666C3.3335 5.13942 4.5274 6.33333 6.00016 6.33333Z" />
      <path d="M13.6665 4.33331V8.33331" />
      <path d="M15.6665 6.33331H11.6665" />
    </AddNewUserSvgIcon>
  );
}

const AddNewUserSvgIcon = withStyles({
  root: {
    color: "#FFFFFF",
    fill: 'none',
    stroke: 'currentColor'
  },
})(SvgIcon);