import React from 'react';
import { UserTable } from '../UserTable';
import { Cell } from '../UserTable/userTableElements';
import { TableRow } from '@material-ui/core';
import type { Consultant } from './types/consultant';
import { columnTitles } from './constants/columnTitles';
import { AvatarWithNameWrapper } from '../AvatarWithNameWrapper';
import { RemoveButton } from '../../styledElements';
import { configJSON } from '../../AdminConsoleController';

export const ConsultantsSection = ({ consultantList, click, page, change }: any) => {
  const consultList = consultantList.map(({ id, attributes: { email, first_name, last_name, created_at, no_of_appointments, no_of_consultants, profile: { data: { attributes: { company } } } } }: any) => {
    const date = new Date(created_at);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    return ({ id, email, first_name, last_name, company, formattedDate, no_of_appointments, no_of_consultants })
  });

  return (
    <UserTable<Consultant>
      rowsPerPage={10}
      columnTitles={columnTitles}
      bodyItems={consultList}
      renderBodyItems={({ id, first_name, last_name, email, company, no_of_appointments, no_of_consultants, formattedDate }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>{first_name + " " + last_name}</AvatarWithNameWrapper>
          </Cell>
          <Cell>{email}</Cell>
          <Cell>{company}</Cell>
          <Cell>{no_of_consultants}</Cell>
          <Cell>{formattedDate}</Cell>
          <Cell>{no_of_appointments}</Cell>
          <Cell>
            <RemoveButton onClick={() => { click(id) }}>{configJSON.removeAccessButtonText}</RemoveButton>
          </Cell>
        </TableRow>
      )}
      page={page}
      change={change}
    />
  );
};
