import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { Nullable } from "./types/nullable";
import { MyProfile } from "./components/Settings/components/MyProfile/types/profile";
import { getRequestMessage } from "./utils/getRequestMessage";
import { EditProfilePayload } from "./components/Settings/components/MyProfile/types/editProfilePayload";
import { RootProfile } from "./types/profile";
import { mapRootProfile } from "./utils/mapRootProfile";

// Customizable Area Start
import {
  IRecommendedConsultant,
  IAppointment,
  IConsultant
} from "./interfaces";

interface IResponseError {
  errors: Record<string, string>[];
}

type EmployeeDashBoard =
  | "dashboard"
  | "appointments"
  | "consultants"
  | "settings"
  | "TermsAndCondition";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPage: EmployeeDashBoard;
  openAddNewAppointment: boolean;
  selectedAppointmentDate: Date | undefined;
  recommendedConsultant: IRecommendedConsultant[];
  myConsultant: IRecommendedConsultant[];
  appointments: IAppointment[];
  currentPage: number;
  totalPages: number;
  cancelFormOpen: boolean;
  appointmentId: string | null;
  search: string;
  timer: number;
  consultantList: IConsultant[];
  selectedConsultantId: null | number | string;
  calendlyUri: string;
  token: string | null;
  openLogoutForm: boolean;
  profileDetail: { full_name: string; email: string };
  userProfile: Nullable<MyProfile>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeDashBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileRequestId: string = "";
  recommendConsultantRequestId: string = "";
  myConsultantRequestId: string = "";
  appointmentRequestId: string = "";
  cancelAppointmentRequestId: string = "";

  //settings page:
  token: Nullable<string> = null;
  getUserProfileRequestId = "";
  updateUserProfileRequestId = "";
  updatePasswordRequestId = "";
  uploadPhotoRequestId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.updateUserPassword = this.updateUserPassword.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPage: "dashboard",
      openAddNewAppointment: false,
      selectedAppointmentDate: undefined,
      recommendedConsultant: [],
      myConsultant: [],
      currentPage: 1,
      totalPages: 0,
      appointments: [],
      cancelFormOpen: false,
      appointmentId: null,
      search: "",
      timer: 0,
      consultantList: [],
      selectedConsultantId: null,
      calendlyUri: "",
      token: null,
      openLogoutForm: false,
      profileDetail: { full_name: "", email: "" },
      userProfile: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const auth = window.localStorage.getItem("auth");
    const token = window.localStorage.getItem("web_token");
    if (auth === "true") {
      this.getProfileDetials();
    } else {
      this.redirectUser("");
    }
    if (token) {
      this.token = token;
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.selectedPage !== this.state.selectedPage) {
      if (this.state.selectedPage === "settings") {
        await this.getUserProfile();
      }
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const dashboardRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseData.data) {
        let rootProfile;
        let userProfile;
        switch (dashboardRequestId) {
          case this.userProfileRequestId:
            const userDetails = JSON.stringify({
              unique_auth_id: responseData.data.attributes.unique_auth_id,
              first_name: responseData.data.attributes.first_name,
              last_name: responseData.data.attributes.last_name,
              role: responseData.data.attributes.role.name
            });
            rootProfile = responseData.data as RootProfile;
            userProfile = mapRootProfile(rootProfile);
            this.setState({ userProfile });
            this.setState({
              profileDetail: {
                full_name: userProfile.name,
                email: userProfile.email
              }
            });
            window.localStorage.setItem("userDetails", userDetails);
            /*istanbul ignore next*/
            this.redirectUser(responseData.data.attributes.role.name);
            /*istanbul ignore next*/
            this.getTableDataRequest();
            break;

          case this.recommendConsultantRequestId:
            this.setState({ recommendedConsultant: responseData.data });
            const filterData = responseData.data.map(
              (consultant: IRecommendedConsultant) => {
                return {
                  id: consultant.id,
                  first_name: consultant.attributes.first_name,
                  last_name: consultant.attributes.last_name,
                  full_name: consultant.attributes.full_name,
                  calendly_uri: consultant.attributes.calendly_uri,
                  email: consultant.attributes.email
                };
              }
            );
            this.setState({ consultantList: filterData });
            break;
          case this.myConsultantRequestId:
            this.setState({
              myConsultant: responseData.data,
              totalPages: responseData.meta.total_pages
            });
            break;
          case this.appointmentRequestId:
            this.setState({
              appointments: responseData.data,
              totalPages: responseData.meta.total_pages
            });
            break;
          case this.cancelAppointmentRequestId:
            this.getTableDataRequest();
            this.setState({ cancelFormOpen: false, appointmentId: null });
            break;
          case this.getUserProfileRequestId:
            rootProfile = responseData.data as RootProfile;
            userProfile = mapRootProfile(rootProfile);
            this.setState({ userProfile });
            break;
          case this.updateUserProfileRequestId:
            await this.getUserProfile();
            break;
          default:
            break;
        }
      } else {
        switch (dashboardRequestId) {
          case this.userProfileRequestId:
            this.errorOfToken(responseData);
            break;

          case this.recommendConsultantRequestId:
            this.errorOfToken(responseData);
            break;
          case this.myConsultantRequestId:
            this.errorOfToken(responseData);
            break;
          case this.appointmentRequestId:
            this.errorOfToken(responseData);
            break;
          case this.cancelAppointmentRequestId:
            this.errorOfToken(responseData.errors);
            break;
          case this.getUserProfileRequestId:
            this.errorOfToken(responseData);
            break;
          case this.updateUserProfileRequestId:
            this.errorOfToken(responseData);
            break;
          default:
            break;
        }
      }
    }
  }

  logoutUser = () => {
    window.localStorage.removeItem("web_token");
    window.localStorage.removeItem("web_refresh_token");
    window.localStorage.removeItem("userDetails");
    window.localStorage.removeItem("auth");
    this.redirectUser("");
  };

  handleOpenLogoutForm = () => {
    this.setState({ openLogoutForm: true });
  };

  handleCloseLogoutForm = () => {
    this.setState({ openLogoutForm: false });
  };

  errorOfToken = (responseData: IResponseError) => {
    const errorName = Object.keys(responseData.errors[0])[0];
    switch (errorName) {
      case "token":
        this.logoutUser();
        break;
      case "test":
        break;
    }
  };

  setSelectedPage = (page: EmployeeDashBoard) => {
    this.setState({ selectedPage: page });
    switch (page) {
      case "dashboard":
        this.setState(
          { recommendedConsultant: [], search: "" },
          this.getTableDataRequest
        );
        break;
      case "appointments":
        this.setState(
          { currentPage: 1, totalPages: 0, appointments: [], search: "" },
          this.getTableDataRequest
        );
        break;
      case "consultants":
        this.setState(
          { currentPage: 1, totalPages: 0, myConsultant: [], search: "" },
          this.getTableDataRequest
        );
        break;
      default:
        break;
    }
  };

  searchDebounce = () => {
    window.clearTimeout(this.state.timer);
    this.setState({
      timer: window.setTimeout(() => {
        this.getTableDataRequest();
      }, 400)
    });
  };

  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { search: event.target.value, currentPage: 1, totalPages: 0 },
      this.searchDebounce
    );
  };

  handleAppointmentPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    this.setState({ currentPage: newPage }, this.getTableDataRequest);
  };

  handleOpenCancelForm = (id: string) => {
    this.setState({ cancelFormOpen: true, appointmentId: id });
  };

  handleCloseCancelForm = () => {
    this.setState({ cancelFormOpen: false, appointmentId: null });
  };

  handleConsultantPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    this.setState({ currentPage: newPage }, this.getTableDataRequest);
  };

  handleOpenAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: true });
  };

  handleOpenAddNewAppointmentTable = (id: string, calendly_uri: string) => {
    this.setState({ openAddNewAppointment: true });
    this.setState({ selectedConsultantId: id, calendlyUri: calendly_uri });
  };

  handleCloseAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: false });
    setTimeout(() => {
      this.setState({ selectedConsultantId: null, calendlyUri: "" });
    }, 1000);
  };

  handleDateChange = (newDate: Date | undefined) => {
    this.setState({ selectedAppointmentDate: newDate });
  };

  handleSelectConsultant = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ selectedConsultantId: event.target.value as string });
    const filterUri = this.state.consultantList.filter(
      item => item.id === event.target.value
    );
    if (filterUri.length > 0) {
      this.setState({ calendlyUri: filterUri[0].calendly_uri });
    } else {
      this.setState({ calendlyUri: "" });
    }
  };

  redirectUser = (role: string) => {
    console.log(
      role,
      window.location.pathname,
      configJSON.employeeDashboardPath
    );
    switch (role) {
      case "admin":
        if (window.location.pathname !== configJSON.adminDashboardPath) {
          window.location.replace(configJSON.adminDashboardPath);
        }
        break;
      case "employee":
        if (window.location.pathname !== configJSON.employeeDashboardPath) {
          window.location.replace(configJSON.employeeDashboardPath);
        }
        break;
      case "consultant":
        if (window.location.pathname !== configJSON.consultantDashboardPath) {
          window.location.replace(configJSON.consultantDashboardPath);
        }
        break;
      default:
        window.location.replace(configJSON.loginPath);
        break;
    }
  };

  getProfileDetials = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/logged_user`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelAppointment = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("web_token")
    };

    let body = {
      data: {
        attributes: {
          status: "rejected"
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelAppointmentRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_calendar/booked_slots/${this.state.appointmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTableDataRequest = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let url;

    switch (this.state.selectedPage) {
      case "dashboard":
        this.recommendConsultantRequestId = requestMessage.messageId;
        if (this.state.search.trim()) {
          url = `account_block/accounts/search?type=consultant&query=${
            this.state.search
          }`;
        } else {
          url = "account_block/accounts/recommended_consultants";
        }
        break;
      case "appointments":
        this.appointmentRequestId = requestMessage.messageId;
        url = `account_block/accounts/employee_appointments?page=${
          this.state.currentPage
        }&per_page=${configJSON.perPageCount}&search_query=${
          this.state.search
        }`;
        break;
      case "consultants":
        this.myConsultantRequestId = requestMessage.messageId;
        url = `account_block/accounts/my_consultants?page=${
          this.state.currentPage
        }&per_page=${configJSON.perPageCount}&search_query=${
          this.state.search
        }`;
        break;
      default:
        break;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //----------------HANDLERS FOR SETTINGS PAGE-----------------------//

  //GET USER PROFILE
  async getUserProfile() {
    const requestMessage = getRequestMessage(
      { token: this.token },
      "account_block/accounts/logged_user",
      configJSON.methodTypeApiGet
    );

    this.getUserProfileRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPDATE USER PROFILE
  async updateUserProfile({
    id,
    name,
    email,
    about,
    expertise,
    skills
  }: EditProfilePayload) {
    const requestMessage = getRequestMessage(
      {
        "Content-Type": "application/json",
        token: this.token
      },
      `account_block/accounts/${id}`,
      configJSON.methodTypeApiPut,
      JSON.stringify({
        data: {
          attributes: {
            full_name: name,
            email,
            profile_attributes: {
              id,
              about_me: about,
              expertise,
              area_of_expertise: skills
            }
          }
        }
      })
    );

    this.updateUserProfileRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPDATE PASSWORD
  async updateUserPassword(old_password: string, new_password: string) {
    let body = new FormData();

    body.append("token", this.token || "");
    body.append("old_password", old_password);
    body.append("new_password", new_password);

    const requestMessage = getRequestMessage(
      { token: this.token },
      "bx_block_forgot_password/create_password",
      configJSON.methodTypeApiPut,
      body
    );

    this.updatePasswordRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPLOAD PHOTO
  async uploadPhoto(file: File, userId: number) {
    let body = new FormData();

    body.append("account[profile_attributes][photo]", file);
    body.append("account[profile_attributes][id]", String(userId));

    const requestMessage = getRequestMessage(
      { token: this.token },
      `account_block/accounts/${userId}/update_profile_pic`,
      configJSON.methodTypeApiPut,
      body
    );

    this.uploadPhotoRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
