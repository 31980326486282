import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { AddAppointmentButton } from "../../styledElements";

interface CancelAppointmentProps {
  title?: string;
  subtitle?: string;
  open: boolean;
  handleClose: () => void;
  cancelAppointment: () => void;
}

export const CancelAppointment = (props: CancelAppointmentProps) => (
  <Dialog open={props.open} onClose={props.handleClose}>
    <DialogWrapper>
      <DialogTitleWrapper>
        <FormTitle>{props.title}</FormTitle>
        <FormSubTitle>{props.subtitle}</FormSubTitle>
        <CloseButton onClick={props.handleClose}>
          <Close />
        </CloseButton>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <ConfirmButton onClick={props.cancelAppointment}>Confirm</ConfirmButton>
        <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
      </DialogContentWrapper>
    </DialogWrapper>
  </Dialog>
);

const DialogWrapper = withStyles({
  root: {
    width: "560px",
    backgroundColor: "#FFFFFF"
  }
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: "24px 32px 15px 32px"
  }
})(DialogTitle);

const DialogContentWrapper = withStyles({
  root: {
    marginLeft: "32px",
    marginRight: "32px",
    marginBottom: "1rem"
  }
})(Box);

const FormTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000"
  }
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    right: 8,
    position: "absolute"
  }
})(IconButton);

const FormSubTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000"
  }
})(Typography);

const ConfirmButton = withStyles({
  root: {
    marginLeft: "auto",
    textTransform: "none",
    letterSpacing: "normal",
    fontFamily: "Source Sans Pro",
    alignItems: "center",
    color: "#FFFFFF",
    padding: "11px 36px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FF0000",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: "rgba(255, 0, 0, 1)"
    }
  }
})(Button);

const CancelButton = withStyles({
  root: {
    fontWeight: 600,
    color: "#2596BE",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  }
})(AddAppointmentButton);
