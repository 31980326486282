import { Appointment, Appointments } from "../types/appointment";
import { RootAppointment } from "../types/rootAppointment";
import { Status } from "../types/status";

export const mapRootAppointments = ({
  attributes: {
    id,
    company: cmpny,
    client,
    status,
    appointment_datetime,
    viewable_slot,
    areas_of_interest
  }
}: RootAppointment): Appointment => {
  const name = client.full_name ? client.full_name : "N/A";
  const company = cmpny || "N/A";
  const skills = areas_of_interest.length > 0 ? areas_of_interest : ["N/A"];
  const date = appointment_datetime || "N/A";
  const duration = viewable_slot.duration || "N/A";
  return {
    id,
    name,
    company,
    skills,
    date,
    duration,
    status
  };
};

export const filteredAppointmentsByStatus = (
  key: Status,
  appointments: Appointments
) => appointments.filter(({ status }) => status === key);
