import { withStyles, styled } from "@material-ui/core/styles";
import { Tabs, Tab, TextField, Avatar, Button } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";

export const Wrapper = styled("div")({});

export const TabsStyled = withStyles({
  root: {
    color: "#2596BE",
    width: "200px",
    "& .MuiTabs-flexContainer": {
      gap: "20px"
    },
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#D9D9D9",
      bottom: "18px",
      zIndex: 1
    },
    "& :first-child": {
      paddingLeft: 0
    }
  }
})(Tabs);

export const TabStyled = withStyles({
  root: {
    textTransform: "none",
    letterSpacing: "normal",
    fontFamily: "Source Sans Pro",
    alignItems: "normal",
    fontWeight: 600,
    fontSize: "16px",
    minWidth: "fit-content",
    minHeight: "auto",
    padding: "0px 5px"
  }
})(Tab);

export const TabIndicator = styled("span")({
  width: "100%",
  backgroundColor: "#2596BE"
});

export const TabBottomLine = styled("div")({
  width: "100%",
  height: "2px",
  backgroundColor: "#D9D9D9",
  position: "absolute",
  bottom: "18px"
});

export const TabsWrapper = styled("div")({
  width: "200px",
  position: "relative"
});

export const FlexColumnWrapper = styled("div")({
  display: "flex",
  flexFlow: "column",
  gap: "10px"
});

export const FlexColumnWrapperMedium = styled(FlexColumnWrapper)({
  maxWidth: "427px"
});

export const FlexColumnWrapperMediumWithErrorSpace = styled(
  FlexColumnWrapperMedium
)({
  position: "relative",
  paddingBottom: "20px"
});

export const Title = styled("div")({
  fontFamily: "Source Sans Pro"
});

export const TitleBold = styled(Title)({
  fontSize: "16px",
  fontWeight: 600
});

export const TitleMedium = styled(Title)({
  fontSize: "16px",
  fontWeight: 400
});

export const TitleLight = styled(Title)({
  fontSize: "14px",
  fontWeight: 400
});

export const TextFieldStyled = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "Source Sans Pro"
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2596BE"
    },

    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2596BE"
    },

    "& .MuiOutlinedInput-input": {
      padding: "16px 14px"
    },

    "& .MuiOutlinedInput-multiline": {
      padding: 0
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E0E0E0"
    }
  }
})(TextField);

export const ProfileWrapper = styled("div")({
  maxWidth: "1000px"
});

export const ProfileHeader = styled("div")({
  display: "flex",
  gap: "35px",
  alignItems: "center",
  padding: "20px 0",
  marginTop: "30px"
});

export const ButtonsWrapper = styled("div")({
  display: "flex",
  gap: "10px"
});

export const AvatarStyled = withStyles({
  root: {
    width: "86px",
    height: "86px"
  }
})(Avatar);

export const ProfileButton = withStyles({
  root: {
    textTransform: "none",
    letterSpacing: "normal",
    fontFamily: "Source Sans Pro",
    alignItems: "normal",
    padding: "12px 30px",
    fontSize: "16px"
  }
})(Button);

export const EditButton = withStyles({
  root: {
    color: "#2596BE",
    borderColor: "#2596BE"
  }
})(ProfileButton);

export const ResetButton = withStyles({
  root: {
    color: "#FF0000",
    borderColor: "#E0E0E0",
    fontWeight: 400
  }
})(ProfileButton);

export const SaveButton = withStyles({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#2596BE",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "rgba(37, 150, 190, 0.6)"
    }
  }
})(ProfileButton);

export const InfoWrapper = styled("div")({
  display: "flex",
  gap: "10px",
  marginTop: "25px",
  marginBottom: "46px"
});

export const Info = styled("div")({
  width: "calc(100% / 3)",
  display: "flex",
  flexFlow: "column nowrap",
  gap: "25px"
});

export const About = styled("div")({
  width: "calc(100% / 3 * 2)",
  display: "flex",
  flexFlow: "column nowrap",
  gap: "40px"
});

export const EditTextfieldsWrapper = styled("div")({
  display: "flex",
  flexFlow: "column",
  maxWidth: "800px",
  margin: "50px 0 70px",
  gap: "20px"
});

export const LocationIcon = withStyles({
  root: {
    fontSize: "16px"
  }
})(LocationOn);

export const LocationWrapper = styled("div")({
  display: "flex",
  gap: "6px",
  alignItems: "center"
});

export const SkillsWrapper = styled("div")({
  display: "flex",
  flexFlow: "row wrap",
  gap: "12px"
});

export const ExpertiseWrapper = styled("div")({
  display: "flex",
  flexFlow: "column",
  gap: "20px"
});

export const SecurityWrapper = styled("div")({
  maxWidth: "427px"
});

export const SecurityTextfieldsWrapper = styled("div")({
  display: "flex",
  flexFlow: "column",
  margin: "50px 0 70px",
  gap: "20px"
});

export const TextFieldErrorTitle = styled(TitleLight)({
  color: "#f44336",
  position: "absolute",
  bottom: 0
});

export const UploadButtonWrapper = styled("div")({
  position: "relative",
  overflow: "hidden",
  display: "inline-block"
});

export const UploadButton = styled(SaveButton)({
  "& .MuiButton-label > input": {
    cursor: "pointer"
  }
});

export const InputFile = styled("input")({
  position: "absolute",
  fontSize: "40px",
  width: "110px",
  left: 0,
  top: 0,
  opacity: 0
});
