import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Appointments } from "./types/appointment";
import { Nullable } from "./types/nullable";
import { mapRootAppointments } from "./utils/mapRootAppointments";
import { RootAppointments } from "./types/rootAppointment";
import { RootProfile } from "./types/profile";
import { mapRootProfile } from "./utils/mapRootProfile";
import { MyProfile } from "./components/Settings/components/MyProfile/types/profile";
import { EditProfilePayload } from "./components/Settings/components/MyProfile/types/editProfilePayload";
import { getRequestMessage } from "./utils/getRequestMessage";

// Customizable Area Start
export type ConsultantPage =
  | "appointments"
  | "requests"
  | "reports"
  | "settings"
  | "terms&conditions";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPage: ConsultantPage;
  openAddNewAppointment: boolean;
  appointments: Appointments;
  pendingAppointments: Appointments;
  loading: boolean;
  userProfile: Nullable<MyProfile>;
  openLogoutForm: boolean;
  openCancelAppointment: {
    appointmentId: Nullable<number>;
    update: Nullable<{ [key: string]: string }>;
    open: boolean;
    message: Nullable<string>;
  };
  search: string;
  currentPage: number;
  totalPages: number;
  timer: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ConsultantDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token: Nullable<string> = null;

  appointmentsRequestId = "";

  pendingAppointmentsRequestId = "";

  updateAppointmentRequestId = "";

  getUserProfileRequestId = "";

  updateUserProfileRequestId = "";

  updatePasswordRequestId = "";

  uploadPhotoRequestId = "";

  removeUserPhotoRequestId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateAppointment = this.updateAppointment.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.updateUserPassword = this.updateUserPassword.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.handleOpenCancelAppointment = this.handleOpenCancelAppointment.bind(
      this
    );
    this.handleCloseCancelAppointment = this.handleCloseCancelAppointment.bind(
      this
    );
    this.handleConfirmCancelAppointment = this.handleConfirmCancelAppointment.bind(
      this
    );
    this.removeUserPhoto = this.removeUserPhoto.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPage: "appointments",
      openAddNewAppointment: false,
      appointments: [],
      pendingAppointments: [],
      loading: false,
      userProfile: null,
      openLogoutForm: false,
      openCancelAppointment: {
        appointmentId: null,
        update: null,
        open: false,
        message: null
      },
      search: "",
      currentPage: 1,
      totalPages: 0,
      timer: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();

    const token = window.localStorage.getItem("web_token");

    if (token) {
      this.token = token;
    }

    if (!this.token) {
      this.props.navigation.navigate("EmailAccountLoginWeb");
    }

    await this.getAppointments();
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.selectedPage !== this.state.selectedPage) {
      if (this.state.selectedPage === "appointments") {
        await this.getAppointments();
      }

      if (this.state.selectedPage === "settings") {
        await this.getUserProfile();
      }

      if (this.state.selectedPage === "requests") {
        await this.getPendingAppointments();
      }
    }
  }

  //GET ALL APPOINTMENTS
  async getAppointments() {
    this.setState({ loading: true });

    const baseUrl = `account_block/accounts/appointments?page=${
      this.state.currentPage
    }&per_page=${configJSON.perPageCount}`;

    const url = this.state.search
      ? `${baseUrl}&query=${this.state.search}`
      : baseUrl;

    const requestMessage = getRequestMessage(
      { token: this.token },
      url,
      configJSON.methodTypeApiGet
    );

    this.appointmentsRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //GET PENDING APPOINTMENTS
  async getPendingAppointments() {
    this.setState({ loading: true });

    const baseUrl = `account_block/accounts/appointment_requests?status=pending&page=${
      this.state.currentPage
    }&per_page=${configJSON.perPageCount}`;

    const url = this.state.search
      ? `${baseUrl}&query=${this.state.search}`
      : baseUrl;

    const requestMessage = getRequestMessage(
      { token: this.token },
      url,
      configJSON.methodTypeApiGet
    );

    this.pendingAppointmentsRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPDATE APPOINTMENT
  async updateAppointment(
    appointmentId: number,
    update: { [key: string]: string }
  ) {
    this.setState({ loading: true });

    const requestMessage = getRequestMessage(
      {
        "Content-Type": "application/json",
        token: this.token
      },
      `/bx_block_calendar/booked_slots/${appointmentId}`,
      configJSON.methodTypeApiPut,
      JSON.stringify({
        data: {
          attributes: update
        }
      })
    );

    this.updateAppointmentRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenCancelAppointment(
    appointmentId: number,
    update: { [key: string]: string }
  ) {
    const messageTemplate = (action: string) =>
      `Are you sure you want to ${action} the appointment.`;

    let message = "";

    let upd = {
      status:
        update.status && update.status === "cancel" ? "rejected" : update.status
    };

    if (update.status && update.status === "accepted") {
      message = messageTemplate("accept");
    }
    if (update.status && update.status === "rejected") {
      message = messageTemplate("reject");
    }

    if (update.status && update.status === "cancel") {
      message = messageTemplate("cancel");
    }

    this.setState({
      openCancelAppointment: {
        appointmentId,
        update: upd,
        message,
        open: true
      }
    });
  }

  handleConfirmCancelAppointment() {
    if (
      this.state.openCancelAppointment.appointmentId &&
      this.state.openCancelAppointment.update
    ) {
      this.updateAppointment(
        this.state.openCancelAppointment.appointmentId,
        this.state.openCancelAppointment.update
      );
      this.handleCloseCancelAppointment();
    }
  }

  handleCloseCancelAppointment() {
    this.setState({
      openCancelAppointment: {
        appointmentId: null,
        update: null,
        open: false,
        message: null
      }
    });
  }

  //GET USER PROFILE
  async getUserProfile() {
    this.setState({ loading: true });

    const requestMessage = getRequestMessage(
      { token: this.token },
      "account_block/accounts/logged_user",
      configJSON.methodTypeApiGet
    );

    this.getUserProfileRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPDATE USER PROFILE
  async updateUserProfile({
    id,
    name,
    email,
    about,
    expertise,
    skills
  }: EditProfilePayload) {
    this.setState({ loading: true });

    const requestMessage = getRequestMessage(
      {
        "Content-Type": "application/json",
        token: this.token
      },
      `account_block/accounts/${id}`,
      configJSON.methodTypeApiPut,
      JSON.stringify({
        data: {
          attributes: {
            full_name: name,
            email,
            profile_attributes: {
              id,
              about_me: about,
              expertise,
              area_of_expertise: skills
            }
          }
        }
      })
    );

    this.updateUserProfileRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //REMOVE USER PHOTO
  async removeUserPhoto(id: number) {
    this.setState({ loading: true });

    const requestMessage = getRequestMessage(
      {
        "Content-Type": "application/json",
        token: this.token
      },
      `account_block/accounts/${id}`,
      configJSON.methodTypeApiPut,
      JSON.stringify({
        data: {
          attributes: {
            profile_attributes: {
              id,
              photo: null
            }
          }
        }
      })
    );

    this.removeUserPhotoRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPDATE PASSWORD
  async updateUserPassword(old_password: string, new_password: string) {
    this.setState({ loading: true });

    let body = new FormData();

    body.append("token", this.token || "");
    body.append("old_password", old_password);
    body.append("new_password", new_password);

    const requestMessage = getRequestMessage(
      { token: this.token },
      "bx_block_forgot_password/create_password",
      configJSON.methodTypeApiPut,
      body
    );

    this.updatePasswordRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //UPLOAD PHOTO
  async uploadPhoto(file: File, userId: number) {
    this.setState({ loading: true });

    let body = new FormData();

    body.append("account[profile_attributes][photo]", file);
    body.append("account[profile_attributes][id]", String(userId));

    const requestMessage = getRequestMessage(
      { token: this.token },
      `account_block/accounts/${userId}/update_profile_pic`,
      configJSON.methodTypeApiPut,
      body
    );

    this.uploadPhotoRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //LOGOUT USER
  logoutUser() {
    window.localStorage.removeItem("web_token");
    window.localStorage.removeItem("web_refresh_token");
    window.localStorage.removeItem("userDetails");
    window.localStorage.removeItem("auth");
    this.props.navigation.navigate("EmailAccountLoginWeb");
  }

  //RECEIVE
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //ERRORS
      if (responseJson !== null && responseJson.errors) {
        if (responseJson.errors[0].token) {
          window.localStorage.removeItem("web_token");
          this.props.navigation.navigate("EmailAccountLoginWeb");
        }
        this.showAlert("Error", JSON.stringify(responseJson.errors));
        return;
      }

      if (!responseJson.data) {
        return;
      }

      //GET APPOINTMENTS
      if (apiRequestCallId === this.appointmentsRequestId) {
        this.setState({ loading: false });
        const appointments = responseJson.data as RootAppointments;
        this.setState({
          appointments: appointments.map(mapRootAppointments),
          totalPages: responseJson.meta.total_pages
        });
      }

      //GET PENDING APPOINTMENTS
      if (apiRequestCallId === this.pendingAppointmentsRequestId) {
        this.setState({ loading: false });
        const appointments = responseJson.data as RootAppointments;
        this.setState({
          pendingAppointments: appointments.map(mapRootAppointments),
          totalPages: responseJson.meta.total_pages
        });
      }

      //UPDATE APPOINTMENT
      if (apiRequestCallId === this.updateAppointmentRequestId) {
        await this.getAppointments();
        await this.getPendingAppointments();
        this.setState({ loading: false });
      }

      //GET USER PROFILE
      if (apiRequestCallId === this.getUserProfileRequestId) {
        const rootProfile = responseJson.data as RootProfile;
        const userProfile = mapRootProfile(rootProfile);
        this.setState({ userProfile });
        this.setState({ loading: false });
      }

      //UPDATE USER PROFILE
      if (apiRequestCallId === this.updateUserProfileRequestId) {
        await this.getUserProfile();
      }

      //REMOVE USER PHOTO
      if (apiRequestCallId === this.removeUserPhotoRequestId) {
        await this.getUserProfile();
      }

      //UPDATE PASSWORD
      if (apiRequestCallId === this.updatePasswordRequestId) {
        this.setState({ loading: false });
      }

      //UPLOAD PHOTO
      if (apiRequestCallId === this.uploadPhotoRequestId) {
        await this.getUserProfile();
      }
    }
  }

  // Customizable Area Start
  setSelectedPage = (page: ConsultantPage) => {
    this.setState({ selectedPage: page });
  };

  handleOpenAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: true });
  };

  handleCloseAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: false });
  };

  handleOpenLogoutForm = () => {
    this.setState({ openLogoutForm: true });
  };

  handleCloseLogoutForm = () => {
    this.setState({ openLogoutForm: false });
  };

  searchDebounce = () => {
    window.clearTimeout(this.state.timer);
    this.setState({
      timer: window.setTimeout(() => {
        if (this.state.selectedPage === "appointments") {
          this.getAppointments();
        }
        if (this.state.selectedPage === "requests") {
          this.getPendingAppointments();
        }
      }, 400)
    });
  };

  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { search: event.target.value, currentPage: 1, totalPages: 0 },
      this.searchDebounce
    );
  };

  handleAppointmentsPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    this.setState({ currentPage: newPage }, this.getAppointments);
  };

  handleRequestsPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    this.setState({ currentPage: newPage }, this.getPendingAppointments);
  };

  // Customizable Area End
}
