import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2596BE"
    },
    background: {
      default: "#eef0f4",
      paper: "#eef0f4"
    },
    success: {
      light: "#08B71A",
      main: "#08B71A"
    },
    error: {
      light: "#FF0000",
      main: "#FF0000"
    }
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFamily: "Source Sans Pro",
    h1: {
      fontWeight: 700,
      lineHeight: 80 / 64,
      fontSize: "40px"
    },
    h2: {
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontSize: "32px"
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "24px"
    },
    h4: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: "20px"
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "18px"
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: "17px",
      cursor: "default"
    },
    subtitle1: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontSize: "16px"
    },
    subtitle2: {
      fontWeight: 400,
      lineHeight: 22 / 14,
      fontSize: "14px",
      cursor: "default"
    },
    caption: {
      lineHeight: 1.5,
      fontSize: "12px"
    },
    button: {
      fontWeight: 400,
      lineHeight: 2,
      fontSize: "16px",
      textTransform: "capitalize"
    },
    body1: {
      lineHeight: 1.5,
      fontSize: "16px"
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: "14px"
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "12px",
      textTransform: "uppercase"
    }
  }
});
