import React from "react";

import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  OutlinedInput,
  styled,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Link } from "react-router-dom";
import BasicLayout from "./Components/BasicLayout";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import {
  StyledErrorLabel,
  StyledInputContainer,
  StyledButton
} from "./Components/CommonStyledComponent";
import { theme } from "./Components/theme";
import { webStyle } from "./Components/webStyles";
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON
} from "./ForgotPasswordController.web";

export default class ResetPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  StyledOutlinedInput = styled(OutlinedInput)(
    (inputTheme: { className: string }) => {
      const {
        borderColor,
        backgroundColor
      } = this.inputBoderAndBackgroundColor(inputTheme.className);

      return {
        backgroundColor: "#ffff",
        "& input": {
          width: "550px"
        },
        "& fieldset": {
          border: borderColor,
          background: backgroundColor
        }
      };
    }
  );
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BasicLayout>
          <Box style={webStyle.containerBoxSection2}>
            {!this.state.passwordResetSuccessFully && (
              <>
                <Typography variant="h6">Create New Passwaord</Typography>
                <Typography variant="subtitle2" style={webStyle.subTitle}>
                  {/* Don’t worry enter your email you will get reset password link to your email */}
                </Typography>
                <Box style={webStyle.formSection}>
                  <StyledInputContainer>
                    <Typography variant="subtitle1">
                      Password
                      <span>
                        <Tooltip
                          title={
                            <>
                              <div>
                                * Password should be 8-15 character in length.
                              </div>
                              <div>
                                * Password should be contain atlease 1 uppercase
                                letter.
                              </div>
                              <div>
                                * Password should be contain atlease 1 lowercase
                                letter.
                              </div>
                              <div>
                                * Password should be contain atlease 1 digit.
                              </div>
                              <div>
                                * Password should be contain atlease 1 special
                                character.
                              </div>
                            </>
                          }
                          arrow
                          placement="right-end"
                        >
                          <IconButton style={webStyle.info}>
                            <InfoOutlinedIcon style={webStyle.infoIcon} />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </Typography>
                    <FormControl variant="outlined">
                      <this.StyledOutlinedInput
                        className={this.inputValidationClassName(
                          this.state.isPasswordValid
                        )}
                        name="password"
                        type={this.inputTypeText(this.state.showPassword)}
                        placeholder="Enter your password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="passwordShowBtn"
                              onClick={this.onShowPassword}
                            >
                              {this.state.showPassword && <VisibilityOffIcon />}
                              {!this.state.showPassword && <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={this.onPasswordInputChange}
                        onBlur={this.validatePassword}
                        value={this.state.resetPassword}
                        data-testid="passwordInput"
                      />
                    </FormControl>
                    <div style={webStyle.error}>
                      <StyledErrorLabel
                        variant="caption"
                        className={this.errorVisibiltyClass(
                          this.state.isPasswordValid === false
                        )}
                      >
                        Please enter a valid password.
                      </StyledErrorLabel>
                    </div>
                  </StyledInputContainer>
                  <StyledInputContainer>
                    <Typography variant="subtitle1">
                      Confirm Password
                    </Typography>
                    <FormControl variant="outlined">
                      <this.StyledOutlinedInput
                        className={this.inputValidationClassName(
                          this.state.isConfirmPasswordMatch
                        )}
                        name="confirm password"
                        type={this.inputTypeText(
                          this.state.showConfirmPassword
                        )}
                        placeholder="Enter your password"
                        endAdornment={
                          <InputAdornment
                            data-testid="passwoedTestInput"
                            position="end"
                          >
                            <IconButton
                              data-testid="confirmPasswordShowBtn"
                              onClick={this.onShowConfirmPassword}
                            >
                              {this.state.showConfirmPassword && (
                                <VisibilityOffIcon />
                              )}
                              {!this.state.showConfirmPassword && (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={this.onConfirmPasswordInputChange}
                        onBlur={() => this.matchPassword()}
                        value={this.state.confirmResetPassword}
                        data-testid="confirmPasswordInput"
                      />
                    </FormControl>
                    <div style={webStyle.error}>
                      <StyledErrorLabel
                        variant="caption"
                        className={this.errorVisibiltyClass(
                          this.state.isConfirmPasswordMatch === false
                        )}
                      >
                        Password does not match.
                      </StyledErrorLabel>
                    </div>
                  </StyledInputContainer>

                  <StyledButton
                    data-testid="resetBtn"
                    variant="contained"
                    onClick={this.onClickResetPassword}
                  >
                    Reset
                  </StyledButton>
                  {
                    <div
                      style={{ ...webStyle.error, ...webStyle.marginBottom }}
                    >
                      <StyledErrorLabel
                        variant="caption"
                        className={this.errorVisibiltyClass(
                          this.state.tokenExpire
                        )}
                      >
                        {this.state.responseMsg}
                      </StyledErrorLabel>
                    </div>
                  }
                </Box>
              </>
            )}

            {this.state.passwordResetSuccessFully && (
              <>
                <Typography variant="h6" data-testid="submissionSuccess">
                  Password reset successfully.
                </Typography>
                <div style={webStyle.buttonContainer}>
                  <Link to={configJSON.loginPath} style={webStyle.link}>
                    <StyledButton
                      variant="contained"
                      style={webStyle.successResetPassword}
                    >
                      Sign In
                    </StyledButton>
                  </Link>
                </div>
              </>
            )}
          </Box>
        </BasicLayout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
