import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { styled } from "@material-ui/core";
import { configJSON } from "../DashboardController.web";
import TableHead from "@material-ui/core/TableHead";
import ImageWithName from "./ImageWithName";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiTableContainer from "@material-ui/core/TableContainer";
import { IRecommendedConsultant } from "../interfaces";

interface Props {
  consultants: IRecommendedConsultant[];
  openAppointmentForm: (id: string, calendly_uri: string) => void;
}

const StyledTableRow = styled(TableRow)({
  fontFamily: "Source Sans Pro",
  "&:last-child": {
    "& td": {
      border: "none"
    }
  }
});

const StyledTableCellContainerDiv = styled("div")({
  display: "flex",
  fontSize: "1rem",
  fontFamily: "Source Sans Pro"
});

const StyledExperties = styled("div")({
  fontWeight: 400,
  backgroundColor: "#2596BE1A",
  color: "#2596BE",
  fontSize: "1rem",
  margin: "0 5px",
  padding: "5px 5px",
  fontFamily: "Source Sans Pro",
  borderRadius: "5px"
});

const RecommendedConsultant = (props: Props) => {
  return (
    <TableContainer elevation={3}>
      <TableContainerLabel variant="h6">
        Recommended consultant
      </TableContainerLabel>
      <TableContainerBox>
        <Table>
          <CunstomTableHead>
            <CustomTableRow>
              {configJSON.employeeConsultantTableHeaderArr.map(
                (label: string) => (
                  <CustomTableCell key={label}>{label}</CustomTableCell>
                )
              )}
            </CustomTableRow>
          </CunstomTableHead>
          <TableBody>
            {props.consultants.map(consultant => (
              <StyledTableRow key={consultant.id}>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    <ImageWithName
                      name={`${consultant.attributes.full_name}`}
                      imgUrl={
                        consultant.attributes.profile.data.attributes.photo
                      }
                    />
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.profile.data.attributes
                      .area_of_expertise &&
                      consultant.attributes.profile.data.attributes.area_of_expertise.map(
                        (experties: string) => (
                          <StyledExperties>{experties}</StyledExperties>
                        )
                      )}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.profile.data &&
                      consultant.attributes.profile.data.attributes.company}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.no_of_employees}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.no_of_employees}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv
                    onClick={() =>
                      props.openAppointmentForm(
                        consultant.id,
                        consultant.attributes.calendly_uri
                      )
                    }
                  >
                    <ScheduleAppointmentWrapper>
                      Schedule Appointment
                    </ScheduleAppointmentWrapper>
                  </StyledTableCellContainerDiv>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerBox>
    </TableContainer>
  );
};

export default RecommendedConsultant;

const TableContainer = withStyles({
  root: {
    padding: "2em 1.5rem",
    marginBottom: "2rem"
  }
})(Paper);

const TableContainerLabel = withStyles({
  root: {
    fontWeight: 600,
    fontSize: "1.125rem",
    fontFamily: "Source Sans Pro"
  }
})(Typography);

const TableContainerBox = withStyles({
  root: {
    marginTop: "1rem",
    fontFamily: "Source Sans Pro",
    maxHeight: "400px",
    position: "relative"
  }
})(MuiTableContainer);

const ScheduleAppointmentWrapper = withStyles({
  root: {
    color: "#2596BE",
    cursor: "pointer"
  }
})(Box);

const CunstomTableHead = withStyles({
  root: {
    zIndex: 3,
    position: "sticky",
    top: "0px"
  }
})(TableHead);

const CustomTableRow = withStyles({
  root: {
    background: "rgba(241, 242, 245, 0.6)"
  }
})(TableRow);

const CustomTableCell = withStyles({
  root: {
    border: "none",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(TableCell);
