import React, { ReactNode } from "react";
import { Box, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  bg_image,
  logo_white
} from "../../../email-account-registration/src/assets";

interface MyComponentProps {
  children: ReactNode;
  // other properties...
}

const BasicLayout: React.FC<MyComponentProps> = props => {
  return (
    <Box>
      <Grid container style={webStyle.container}>
        <Grid item xs={12} md={6} lg={4} style={webStyle.positionRelative}>
          <Box style={webStyle.containerWrapper}>
            <img src={logo_white} alt="" />

            <Box style={webStyle.containerBox}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={1}>
                  <CheckCircleIcon style={webStyle.checkCircleIcon} />
                </Grid>
                <Grid item xs={11}>
                  <span style={webStyle.sectionPoint}>
                    A Solution for Attrition, Workplace Frustration, Burnout,
                    Culture Challenges to increase retention, productivity, and
                    employee investment{" "}
                  </span>
                </Grid>
                <Grid item xs={1}>
                  <CheckCircleIcon style={webStyle.checkCircleIcon} />
                </Grid>
                <Grid item xs={11}>
                  <span style={webStyle.sectionPoint}>
                    It helps employees navigate the job within the job through
                    unlocking and maximizing an improved and empowered workplace
                    experience.
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <img src={bg_image} style={webStyle.bgImage} />
        </Grid>
        <Grid item xs={12} md={6} lg={8} style={webStyle.positionRelative}>
          {props.children}
          <div style={webStyle.imageBottom}>
            <svg
              width="316"
              height="282"
              viewBox="0 0 316 282"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M315.459 166.361C315.53 230.305 265.357 281.268 202.462 281.111C140.336 280.955 89.8073 230.448 89.5227 168.224C89.2381 106.199 139.71 55.066 201.708 54.5683C263.834 54.0707 315.373 104.734 315.445 166.375L315.459 166.361ZM276.939 175.476C276.313 125.21 237.04 83.8602 190.595 84.5712C144.52 85.2821 104.947 128.466 105.517 177.409C106.086 226.594 146.626 269.323 192.117 268.683C237.879 268.044 277.523 224.461 276.925 175.461L276.939 175.476Z"
                fill="#2596BE"
                fill-opacity="0.1"
              />
              <path
                d="M18.0624 0.179419C29.3179 -1.21408 39.0936 5.65388 40.3173 15.7638C41.5268 25.7458 33.4587 35.0595 22.4593 36.3961C11.4457 37.7327 1.30004 30.5804 0.118993 20.6695C-1.07628 10.6164 6.86378 1.5587 18.0624 0.179419ZM21.9328 10.6022C15.4726 11.4554 10.8765 17.1431 11.773 23.1579C12.641 28.9594 18.4039 33.1399 24.608 32.4574C30.997 31.7464 35.7781 26.0729 35.0524 20.0439C34.3267 14.0006 28.4072 9.73483 21.9328 10.6022Z"
                fill="#2596BE"
                fill-opacity="0.1"
              />
              <path
                d="M45.9519 58.4645C46.0515 38.1593 60.466 22.3331 78.7651 22.4753C96.7939 22.6033 111.521 39.0551 111.393 58.9053C111.265 78.7697 96.239 95.1789 78.3383 95.0225C60.1814 94.8518 45.8808 78.6986 45.9661 58.4645H45.9519ZM65.3752 53.957C65.4321 65.6311 74.4963 75.0869 85.4103 74.8736C95.9259 74.6603 104.677 65.3751 104.862 54.1987C105.061 42.681 96.0398 32.8981 85.1684 32.8128C74.2402 32.7275 65.3183 42.2544 65.3752 53.957Z"
                fill="#2596BE"
                fill-opacity="0.1"
              />
            </svg>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

const webStyle = {
  container: {
    backgroundColor: "#EEF0F4",
    minHeight: "100vh"
  },
  containerWrapper: {
    position: "inherit",
    zIndex: 1,
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2749) 47.95%, rgba(0, 0, 0, 0) 100%)`,
    padding: "6rem 4rem"
  },
  containerBox: {
    margin: "3rem 0",
    padding: "1rem 0"
  },
  checkCircleIcon: {
    color: "white",
    fontSize: "1rem",
    marginTop: "4px"
  },
  sectionPoint: {
    color: "white",
    fontSize: "20px",
    fontFamily: "Source Sans Pro",
    cursor: "default"
  },
  positionRelative: {
    position: "relative"
  },
  bgImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0
  },
  imageBottom: {
    position: "absolute",
    bottom: "1rem",
    right: 0
  }
} as Record<string, Record<string, string | number>>;

export default BasicLayout;
