type Options = "special" | "digit" | "lower" | "upper";

export const validateStringOnAtLeastOneChar = (
  str: string,
  option: Options
) => {
  const regs = {
    special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    lower: /(?=.*[a-z])/,
    upper: /(?=.*[A-Z])/,
    digit: /(?=.*\d)/
  };
  return regs[option].test(str);
};
