import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const HomeIcon = () => {
  return (
    <HomeSvgIcon style={{color: 'inherit'}} >
      <path
        d="M19.707 7.69825L12.157 1.56325C11.232 0.81225 9.89 0.81225 8.965 1.56325L1.416 7.69725C1.151 7.91225 1 8.23025 1 8.57025V20.1132C1 20.7332 1.505 21.2432 2.125 21.2432H7.187C7.807 21.2432 8.312 20.7332 8.312 20.1132V12.8073H12.811V20.1132C12.811 20.7332 13.316 21.2432 13.936 21.2432H18.998C19.618 21.2432 20.123 20.7332 20.123 20.1132V8.57025C20.122 8.23025 19.971 7.91225 19.707 7.69825ZM18.997 20.1192H13.935V12.8022C13.935 12.1823 13.43 11.6832 12.81 11.6832H8.311C7.691 11.6832 7.186 12.1823 7.186 12.8022V20.1192H2.125V8.57025L9.675 2.43625C10.188 2.01825 10.935 2.01925 11.448 2.43625L18.998 8.57025L18.997 20.1192Z" />
    </HomeSvgIcon>
  );
}

const HomeSvgIcon = withStyles({
  root: {
    color: "#1A1A1A",
    strokeWidth: 0.5,
    stroke: 'currentColor',
  },
})(SvgIcon);