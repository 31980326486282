import React from "react";
import { Box } from "@material-ui/core";
import { activeEmployees, activeConsultant, notifications } from "./mocks";
import { withStyles, styled } from "@material-ui/core/styles";

export const ReportsSection = () => {
  return (
    <Wrapper>
      <MainSectionWrapper>
        <Image src={activeEmployees} alt="mock image" />

        <Box sx={{ display: "flex", gridGap: "20px" }}>
          <Box>
            <Image src={activeConsultant} alt="mock image" />
          </Box>

          <ApprovalRequestImgWrapper>
            <Image src={activeConsultant} alt="mock image" />
          </ApprovalRequestImgWrapper>
        </Box>
      </MainSectionWrapper>

      <NotificationImgWrapper>
        <Image src={notifications} alt="mock image" />
      </NotificationImgWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  gap: "20px"
});

const Image = styled("img")({
  width: "100%"
});

const MainSectionWrapper = withStyles({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: "30px"
  }
})(Box);

const ApprovalRequestImgWrapper = withStyles({})(Box);

const NotificationImgWrapper = withStyles({
  root: {
    maxWidth: "300px"
  }
})(Box);
