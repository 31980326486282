import { withStyles } from '@material-ui/core/styles';
import { Box, Button, InputBase,Typography,Divider,Link } from '@material-ui/core';

export const SearchInput = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',

    "& .MuiInputBase-input": {
      padding: 0,
      height: "20px"
    }
  },
})(InputBase);

export const MenuButton = withStyles({
  root: {
    padding: '12px 50px 12px 0px',
    textTransform: 'none',
    letterSpacing: 'normal',
    fontFamily: 'Source Sans Pro',
    alignItems: 'normal',
    color: '#1A1A1A',

    '&:hover': {
      color: '#2596BE',
      backgroundColor: 'transparent',
      fontWeight: '400',
    },
    '&:hover .MuiSvgIcon-root': {
      color: '#2596BE',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      marginRight: '2px',
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})(Button);

export const MenuButtonText = withStyles({
  root: {
    display: 'grid',
    fontSize: '16px',
    '&::after': {
      content: 'attr(data-text)',
      const: 'attr(data-text) / ""',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      pointerEvents: 'none',
      fontWeight: '700',
    },
  },
})(Box);

export const FooterMenuButton = withStyles({
  root: {
    '& .MuiButton-startIcon': {
      marginRight: '10px',
    },
  },
})(MenuButton);

export const TermsAndConditionsButton = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    '& .MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
})(FooterMenuButton);

export const TermsAndConditionsButtonText = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuButtonText);

export const BellIconWrapper = withStyles({
  root: {
    position: 'absolute',
    color: '#2596BE',
    top: -4,
    right: -1,
    '& .MuiSvgIcon-root': {
      width: '8px',
      height: '8px',
    },
  },
})(Box);

export const AddNewUserButton = withStyles({
  root: {
    marginLeft: 'auto',
    textTransform: 'none',
    letterSpacing: 'normal',
    fontFamily: 'Source Sans Pro',
    alignItems: 'center',
    backgroundColor: '#2596BE',
    color: '#FFFFFF',
    padding: '11px 36px',
    fontSize: '16px',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'rgba(37, 150, 190, 0.6)',
    },
  },
})(Button);

export const ExpandMoreIconWrapper = withStyles({
  root: {
    color: '#000000',
  },
})(Box);

export const PageSectionHeaderTitle = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    color: '#000000',
    fontWeight: 600,
    fontSize: '36px',
  },
})(Typography);

export const PagesContainer = withStyles({
  root: {
    width: '100%',
    marginTop: '30px',
  },
})(Box);

export const PendingApprovalsWrapper = withStyles({
  root: {
    boxSizing: "border-box",
    height:"445px",
    backgroundColor:"#FFFFFF" , 
    padding:"25px 32px 52px 32px",
    overflow:"auto"
  },
})(Box);

export const PendingApprovalsBox = withStyles({
  root: {
    marginTop:"20px"
  },
})(Box);

export const PendingApprovalsButtonBox = withStyles({
  root: {
    marginLeft:"-7px"
  },
})(Box);

export const RemoveButton = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'none',
    color: '#BE4D25',
  },
})(Button);

export const AcceptButton = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontSize: '16px',
    textTransform: 'none',
    color: '#2596BE',
    
  },
})(Button);

export const DeclineButton = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    // fontWeight: 200,
    fontSize: '16px',
    textTransform: 'none',
    color: '#BE4D25',
  },
})(Button);

export const PendingApprovalsText = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    color: '#5E5E5E',
    // fontWeight: 200,
    fontSize: '16px',
  },
})(Typography);

export const PendingApprovalsHeading = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    color: '#000000',
    fontWeight: 600,
    fontSize: '24px',
  },
})(Typography);

export const DividerList = withStyles({
  root:{
    marginTop:"10px",
    height:"2px"
  }
})(Divider);

export const MailLink = withStyles({
  root:{
   color:"#2596BE",
   textDecoration:"none"
  }
})(Link)