import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core";
import { upComingEvent, group } from "../assets";

const StyledContainer = styled(Paper)({
  padding: "1.3rem 1.5rem"
});

const StyledLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: "1.125rem",
  fontFamily: "Source Sans Pro"
});

const StyledImageContainer = styled(Box)({
  marginTop: "1rem",
  marginBottom: ".5rem"
});

const StyledImage = styled("img")({
  borderRadius: "4px",
  marginBottom: "4px"
});

const StyledGroupSectionContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",

  "& div span": {
    fontSize: "14px",
    fontWeight: 400,
    cursor: "default"
  }
});

const StyledLocation = styled("div")({
  fontSize: "14px",
  fontWeight: 400,
  color: "#2596BE",
  cursor: "pointer"
});

const StyledEventTitle = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 600,
  fontFamily: "Source Sans Pro"
});

const StyledButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: ".7rem"
});

const StyledReserveSeatBtn = styled(Button)({
  textTransform: "none",
  letterSpacing: "normal",
  fontFamily: "Source Sans Pro",
  alignItems: "center",
  backgroundColor: "#2596BE",
  color: "#FFFFFF",
  padding: "11px 36px",
  fontSize: "16px",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "rgba(37, 150, 190, 0.6)"
  }
});

const StlyedNotIntrestedBtn = styled("div")({
  lineHeight: 3.2,
  fontSize: "1rem",
  fontFamily: "Source Sans Pro",
  fontWeight: 400,
  color: "#BE4D25",
  cursor: "pointer"
});

export const UpComingWebniar = () => {
  return (
    <StyledContainer elevation={3}>
      <StyledLabel variant="h6">Upcoming Webinar</StyledLabel>
      <StyledImageContainer>
        <StyledImage src={upComingEvent} alt="Up Coming event" />
        <StyledGroupSectionContainer>
          <div>
            <img src={group} alt="" /> <span>20</span>
          </div>
          <StyledLocation>Location</StyledLocation>
        </StyledGroupSectionContainer>
      </StyledImageContainer>
      <StyledEventTitle variant="h6">Lorem ipsum event title</StyledEventTitle>

      <StyledButtonContainer>
        <StyledReserveSeatBtn>Reserve Seat</StyledReserveSeatBtn>
        <StlyedNotIntrestedBtn>Not intrested</StlyedNotIntrestedBtn>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
