import { configJSON } from '../../../AdminConsoleController';

export const columnTitles = [
  configJSON.employeesTableColumnName,
  configJSON.employeesTableColumnEmail,
  configJSON.employeesTableColumnCompany,
  configJSON.employeesTableColumnConsultant,
  configJSON.employeesTableColumnDate,
  configJSON.employeesTableColumnAppointments,
  configJSON.employeesTableColumnAction,
];