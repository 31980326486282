Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";
exports.btnExampleTitle = "CLICK ME";
exports.searchPlaceholder = "Search for people";
exports.dashboardButtonText = 'Dashboard';
exports.employeesButtonText = 'Employees';
exports.consultantsButtonText = 'Consultants';
exports.logoutButtonText = 'Log out';
exports.termsAndConditionsButtonText = 'Terms & Conditions';
exports.addNewUserButtonText = 'Add New User';
exports.dashboardPageTitle = 'Dashboard';
exports.employeesPageTitle = 'Employees';
exports.consultantsPageTitle = 'Consultants';

exports.addUserFormTitle = 'Add New User';
exports.addUserFormSelectEmployee = 'Employee';
exports.addUserFormSelectConsultant = 'Consultant';
exports.addUserFormSubTitle = 'Lorem ipsum is a dummy text.';
exports.addUserFormEmailInputPlaceholder = 'example@mail.com';
exports.addUserFormLinkInputPlaceholder = 'http://www.example.com/link-lorem-ipsum';
exports.addUserFormLinkInpuLabel = 'Link to invite';
exports.addUserFormSendInviteButtonText = 'Send Invite';
exports.addUserFormSendCopyLinkButtonText = 'Copy';

exports.removeAccessButtonText = 'Remove Access';

exports.employeesTableColumnName = 'Employee Name';
exports.employeesTableColumnEmail = 'Email';
exports.employeesTableColumnCompany = 'Company';
exports.employeesTableColumnConsultant = 'No. of Consultants';
exports.employeesTableColumnDate = 'Registered Date';
exports.employeesTableColumnAppointments = 'No. of Appointments';
exports.employeesTableColumnAction = 'Action';

exports.consultantsTableColumnName = 'Consultant Name';
exports.consultantsTableColumnEmail = 'Email';
exports.consultantsTableColumnCompany = 'Company';
exports.consultantsTableColumnConsultant = 'No. of Employees';
exports.consultantsTableColumnDate = 'Registered Date';
exports.consultantsTableColumnAppointments = 'No. of Appointments';
exports.consultantsTableColumnAction = 'Action';

exports.loginPath = "/login"
// Customizable Area End