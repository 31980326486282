import React, { Component } from "react";
import { TabPanel } from "./components/TabPanel";
import { SettingsProps } from "./types/settingsProps";
import { SettingsState } from "./types/settingsState";
import { MyProfile } from "./components/MyProfile";
import {
  Wrapper,
  TabsWrapper,
  TabsStyled,
  TabIndicator,
  TabStyled,
  TabBottomLine
} from "./styles/styledElements";
import { configJSON } from "../../ConsultantDashboardController.web";
import { Security } from "./components/Security";

export class Settings extends Component<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {
      currentTab: 0
    };
    this.setCurrentTab = this.setCurrentTab.bind(this);
  }

  setCurrentTab(event: object, currentTab: number) {
    this.setState({ currentTab });
  }

  render() {
    return (
      <Wrapper>
        <TabsWrapper>
          <TabsStyled
            id="profile"
            value={this.state.currentTab}
            onChange={this.setCurrentTab}
            TabIndicatorProps={{ children: <TabIndicator /> }}
          >
            <TabStyled
              id="security"
              label={configJSON.profileTabTitle}
              style={this.state.currentTab !== 0 ? webStyle.tabColor : {}}
            />
            <TabStyled
              label={configJSON.securityTabTitle}
              style={this.state.currentTab !== 1 ? webStyle.tabColor : {}}
            />
          </TabsStyled>
          <TabBottomLine />
        </TabsWrapper>
        <TabPanel currentTab={this.state.currentTab} index={0}>
          <MyProfile
            consultantSettings={this.props.consultantSettings}
            data={this.props.data}
            updateProfile={this.props.updateProfile}
            uploadPhoto={this.props.uploadPhoto}
            removeUserPhoto={this.props.removeUserPhoto}
          />
        </TabPanel>
        <TabPanel currentTab={this.state.currentTab} index={1}>
          <Security updatePassword={this.props.updatePassword} />
        </TabPanel>
      </Wrapper>
    );
  }
}

const webStyle = {
  tabColor: { color: "#5E5E5E" }
} as Record<string, Record<string, string | number>>;
