export const webStyle = {
  container: {
    backgroundColor: "#EEF0F4",
    minHeight: "100vh"
  },
  containerWrapper: {
    position: "inherit",
    zIndex: 1,
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2749) 47.95%, rgba(0, 0, 0, 0) 100%)`,
    padding: "6rem 4rem"
  },
  containerBox: {
    margin: "3rem 0",
    padding: "1rem 0"
  },
  checkCircleIcon: {
    color: "white",
    fontSize: "1rem",
    marginTop: "4px"
  },
  sectionPoint: {
    color: "white",
    fontSize: "20px",
    fontFamily: "Source Sans Pro",
    cursor: "default"
  },
  positionRelative: {
    position: "relative"
  },
  containerBoxSection2: {
    marginTop: "6.5rem ",
    marginRight: "3.5rem",
    marginLeft: "7rem",
    padding: "5rem 0"
  },
  bgImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0
  },
  formSection: {
    marginTop: "3rem"
  },
  errorIcon: {
    color: "#FF1D1D"
  },
  successIcon: {
    color: "#08B71A"
  },
  imageBottom: {
    position: "absolute",
    bottom: "1rem",
    right: 0
  },
  subTitle: {
    color: "#5E5E5E",
    width: "450px"
  },
  error: {
    color: "#FF1D1D",
    marginTop: "5px",
    marginLeft: "2px"
  },
  info: {
    padding: 0,
    marginLeft: "2px",
    top: "-1"
  },
  infoIcon: {
    fontSize: "1.1rem"
  },
  buttonContainer: {
    marginTop: "1rem"
  },
  link: {
    textDecoration: "none",
    color: "#ffff"
  },
  marginBottom: {
    marginBottom: ".5rem"
  },
  success: {
    color: "#08B71A",
    marginTop: "5px",
    marginLeft: "2px"
  },
  successResetPassword: {
    marginTop: "10px"
  }
} as Record<string, Record<string, string | number>>;
