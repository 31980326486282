import React from "react";

import {
  Box,
  Typography,
  InputAdornment,
  // Customizable Area Start
  FormControl,
  OutlinedInput,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Link } from "react-router-dom";
import BasicLayout from "./Components/BasicLayout";
import {
  StyledErrorLabel,
  StyledIconButton,
  StyledInputContainer,
  StyledButton
} from "./Components/CommonStyledComponent";
import { theme } from "./Components/theme";
import { webStyle } from "./Components/webStyles";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#2596BE",
  margin: "0 5px"
});

// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  StyledOutlinedInput = styled(OutlinedInput)(
    (inputTheme: { className: string }) => {
      const {
        borderColor,
        backgroundColor
      } = this.inputBoderAndBackgroundColor(inputTheme.className);
      return {
        backgroundColor: "#ffff",
        "& fieldset": {
          background: backgroundColor,
          border: borderColor
        },
        "& input": {
          width: "550px"
        }
      };
    }
  );
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BasicLayout>
          <Box style={webStyle.containerBoxSection2}>
            <Typography variant="h6">Forgot Password?</Typography>
            <Typography variant="subtitle2" style={webStyle.subTitle}>
              Don’t worry enter your email you will get reset password link to
              your email
            </Typography>
            <Box style={webStyle.formSection}>
              <StyledInputContainer>
                <Typography variant="subtitle1">Email</Typography>
                <FormControl variant="outlined">
                  <this.StyledOutlinedInput
                    className={this.inputValidationClassName(
                      this.state.isEmailValid
                    )}
                    type="text"
                    name="email"
                    placeholder="example@mail.com"
                    endAdornment={
                      <InputAdornment position="end">
                        <StyledIconButton
                          disabled
                          className={
                            this.state.isEmailValid === null ? "hidden" : ""
                          }
                        >
                          {this.state.isEmailValid && (
                            <CheckCircleOutlineOutlined
                              style={webStyle.successIcon}
                            />
                          )}
                          {!this.state.isEmailValid && (
                            <InfoOutlinedIcon style={webStyle.errorIcon} />
                          )}
                        </StyledIconButton>
                      </InputAdornment>
                    }
                    onChange={this.onEmailInputChange}
                    onBlur={() => this.validateEmail()}
                    value={this.state.email}
                    data-testid="emailInput"
                  />
                </FormControl>
                <div style={webStyle.error}>
                  <StyledErrorLabel
                    variant="caption"
                    className={this.errorVisibiltyClass(
                      this.state.isEmailValid === false
                    )}
                  >
                    Please enter a valid email address.
                  </StyledErrorLabel>
                </div>
              </StyledInputContainer>

              <StyledButton
                data-testid="submitBtn"
                variant="contained"
                onClick={this.onClickForgotPasswordSubmit}
              >
                Submit
              </StyledButton>
              {this.state.isForgetPasswordError && (
                <div style={{ ...webStyle.error, ...webStyle.marginBottom }}>
                  <StyledErrorLabel
                    variant="caption"
                    className={this.errorVisibiltyClass(true)}
                  >
                    {this.state.responseMsg}
                  </StyledErrorLabel>
                </div>
              )}
              {this.state.isPasswordLinkSent && (
                <div style={{ ...webStyle.success, ...webStyle.marginBottom }}>
                  <Typography variant="caption" style={webStyle.success}>
                    {this.state.responseMsg}
                  </Typography>
                </div>
              )}
              <StyledInputContainer>
                Need to go back ?
                <span>
                  <StyledLink to={configJSON.loginPath}>Sign In</StyledLink>
                </span>
              </StyledInputContainer>
            </Box>
          </Box>
        </BasicLayout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
