import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  withStyles,
} from "@material-ui/core";

import { Close,  } from "@material-ui/icons";
import { AddNewUserButton } from "../../styledElements";

export const RemoveAccessForm = ({ open, onClose, remove }: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogWrapper>
        <DialogTitleWrapper>
          <RemoveAccessFormTitle>Are you sure?</RemoveAccessFormTitle>
          <RemoveAccessFormSubTitle>
            Are you sure you want to remove the access
          </RemoveAccessFormSubTitle>

          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </DialogTitleWrapper>

        <DialogContentWrapper>
          <ButtonWrapper>
            <RemoveButton onClick={remove} >Remove</RemoveButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          </ButtonWrapper>
        </DialogContentWrapper>
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = withStyles({
  root: {
    width: "560px",
    height: "194px",
    backgroundColor: "#FFFFFF",
  },
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: "24px 28px",
  },
})(DialogTitle);

const DialogContentWrapper = withStyles({
  root: {
    display: "flex",
    // flexFlow: 'column nowrap',
    gap: "15px",
  },
})(DialogContent);

const RemoveAccessFormTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000",
  },
})(Typography);

const RemoveAccessFormSubTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
  },
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    right: 8,
    position: "absolute",
  },
})(IconButton);

const ButtonWrapper = withStyles({
  root: {
    display: "flex",
    gap: "14px",
  },
})(Box);

const RemoveButton = withStyles({
  root: {
    fontWeight: 600,
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    "&:hover": {
      backgroundColor: "#FF0000",
      border: "1px solid #2596BE",
    },
  },
})(AddNewUserButton);

const CancelButton = withStyles({
  root: {
    fontWeight: "lighter",
    color: "#2596BE",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #2596BE",
    },
  },
})(AddNewUserButton);

