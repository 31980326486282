import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const AppointmentsIcon = () => {
  return (
    <AppointmentsSvgIcon
      style={{ color: "inherit" }}
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M4.8125 -6.10352e-05C4.57088 -6.10352e-05 4.375 0.194111 4.375 0.433635V2.60211H1.75C0.783502 2.60211 0 3.3788 0 4.33689V18.2151C0 19.1732 0.783502 19.9499 1.75 19.9499H19.25C20.2165 19.9499 21 19.1732 21 18.2151V4.33689C21 3.3788 20.2165 2.60211 19.25 2.60211H16.625V0.433635C16.625 0.194111 16.4291 -6.10352e-05 16.1875 -6.10352e-05H15.3125C15.0709 -6.10352e-05 14.875 0.194111 14.875 0.433635V2.60211H6.125V0.433635C6.125 0.194111 5.92912 -6.10352e-05 5.6875 -6.10352e-05H4.8125ZM14.875 4.33689H6.125V5.20429C6.125 5.44381 5.92912 5.63798 5.6875 5.63798H4.8125C4.57088 5.63798 4.375 5.44381 4.375 5.20429V4.33689H2.625C2.14175 4.33689 1.75 4.72524 1.75 5.20429V6.93905H19.25V5.20429C19.25 4.72524 18.8583 4.33689 18.375 4.33689H16.625V5.20429C16.625 5.44381 16.4291 5.63798 16.1875 5.63798H15.3125C15.0709 5.63798 14.875 5.44381 14.875 5.20429V4.33689ZM1.75 8.67387H19.25V17.3478C19.25 17.8268 18.8583 18.2151 18.375 18.2151H2.625C2.14175 18.2151 1.75 17.8268 1.75 17.3478V8.67387Z" />
    </AppointmentsSvgIcon>
  );
};

const AppointmentsSvgIcon = withStyles({
  root: {
    color: "#1A1A1A"
  }
})(SvgIcon);
