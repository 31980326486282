import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  isEmailValid: boolean | null;
  isPasswordLinkSent: boolean;
  isForgetPasswordError: boolean;
  responseMsg: string;
  resetPassword: string;
  confirmResetPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  isPasswordValid: boolean | null;
  isConfirmPasswordMatch: boolean | null;
  passwordResetSuccessFully: boolean;
  token: string | null;
  tokenExpire: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subScribedMsg: string[];
  forgotPasswordRequestId: string = "";
  profileRequestId: string = "";
  resetPasswordRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AuthTokenDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      isEmailValid: null,
      isForgetPasswordError: false,
      isPasswordLinkSent: false,
      responseMsg: "",
      confirmResetPassword: "",
      isConfirmPasswordMatch: null,
      isPasswordValid: null,
      resetPassword: "",
      showConfirmPassword: false,
      showPassword: false,
      passwordResetSuccessFully: false,
      token: null,
      tokenExpire: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));

      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestId) {
        case this.forgotPasswordRequestId:
          if (responseJson.success) {
            this.setState({
              isForgetPasswordError: false,
              isPasswordLinkSent: true,
              responseMsg: responseJson.success
            });
          } else if (responseJson.errors) {
            this.setState({
              isForgetPasswordError: true,
              isPasswordLinkSent: false,
              responseMsg: responseJson.errors
            });
          }
          break;
        case this.profileRequestId:
          if (responseJson.data) {
            this.redirectUser(responseJson.data.attributes.role.name);
          } else if (responseJson.errors) {
            window.localStorage.removeItem("web_token");
            window.localStorage.removeItem("web_refresh_token");
            window.localStorage.removeItem("userDetails");
            window.localStorage.removeItem("auth");
          }
          break;
        case this.resetPasswordRequestId:
          if (responseJson.status) {
            this.setState({ passwordResetSuccessFully: true });
          } else if (responseJson.errors) {
            const indexOfError = 0;
            this.setState({
              responseMsg: responseJson.errors[indexOfError].token,
              tokenExpire: true
            });
          }
          break;
        default:
          break;
      }
    }

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const auth = window.localStorage.getItem("auth");
    const params = this.getQueryParams();

    if (params.token) {
      this.setState({ token: params.token });
    }

    if (auth === "true") {
      this.getUserProfileDetials();
    }
  }

  validateEmail = (callBack?: () => void) => {
    if (/\w+/.test(this.state.email)) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/i;
      if (emailRegex.test(this.state.email)) {
        this.setState({ isEmailValid: true }, () => {
          if (typeof callBack === "function") {
            callBack();
          }
        });
      } else {
        this.setState({ isEmailValid: false });
      }
    } else {
      this.setState({ isEmailValid: false });
    }
  };

  onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value.trim() }, () =>
      this.validateEmail()
    );
  };

  inputValidationClassName = (success: boolean | null): string => {
    switch (success) {
      case true:
        return "success";
      case false:
        return "fail";
      default:
        return "";
    }
  };

  inputBoderAndBackgroundColor = (msg: string) => {
    switch (msg) {
      case "success": {
        return {
          borderColor: "1px solid rgba(8, 183, 26, 0.2)",
          backgroundColor: "rgba(8, 183, 26, 0.04)"
        };
      }
      case "fail": {
        return {
          borderColor: "1px solid #FF0000",
          backgroundColor: "rgba(255, 0, 0, 0.02)"
        };
      }
      default: {
        return { borderColor: "", backgroundColor: "" };
      }
    }
  };

  errorVisibiltyClass = (visible: boolean | null) => {
    if (visible) {
      return "visible";
    } else {
      return "";
    }
  };

  redirectUser = (
    role: string,
    mockWindowObj?: { location: { replace: Function } }
  ) => {
    const redirectWindow = mockWindowObj || window;
    switch (role) {
      case "admin":
        redirectWindow.location.replace(configJSON.adminDashboardPath);
        break;
      case "employee":
        redirectWindow.location.replace(configJSON.employeeDashboardPath);
        break;
      case "consultant":
        redirectWindow.location.replace(configJSON.consultantDashboardPath);
        break;
      default:
        redirectWindow.location.replace(configJSON.loginPath);
    }
  };

  inputTypeText = (showPass: boolean): string => {
    if (showPass) {
      return "text";
    } else {
      return "password";
    }
  };

  matchPassword = (callBack?: () => void) => {
    console.log(callBack);
    if (
      this.state.confirmResetPassword === this.state.resetPassword &&
      this.state.confirmResetPassword.length > 0
    ) {
      this.setState({ isConfirmPasswordMatch: true }, () => {
        if (typeof callBack === "function") {
          callBack();
        }
      });
    } else {
      this.setState({ isConfirmPasswordMatch: false });
    }
  };

  validatePassword = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    if (regex.test(this.state.resetPassword)) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };

  onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ resetPassword: event.target.value.trim() }, () =>
      this.validatePassword()
    );
  };

  onConfirmPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ confirmResetPassword: event.target.value.trim() }, () =>
      this.matchPassword()
    );
  };

  onShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  };

  onShowConfirmPassword = () => {
    if (this.state.showConfirmPassword) {
      this.setState({ showConfirmPassword: false });
    } else {
      this.setState({ showConfirmPassword: true });
    }
  };

  onClickForgotPasswordSubmit = () => {
    this.validateEmail(this.forgetPasswordRequest);
  };

  onClickResetPassword = () => {
    const callBackFuction = () => {
      if (this.state.isConfirmPasswordMatch) {
        this.resetPasswordRequest();
      }
    };

    this.matchPassword(callBackFuction);
  };

  resetPasswordRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const formData = new window.FormData();
    formData.append("token", this.state.token as string);
    formData.append("password_confirmation", this.state.confirmResetPassword);
    formData.append("new_password", this.state.resetPassword);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_forgot_password/create_password`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  forgetPasswordRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const formData = new window.FormData();
    formData.append("data[email]", this.state.email);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/generate_password_link`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfileDetials = async () => {
    const token = window.localStorage.getItem("web_token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/logged_user`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQueryParams = () => {
    const queryParams = new window.URLSearchParams(window.location.search);
    const params: Record<string, string | undefined> = {};
    for (const [key, value] of queryParams) {
      params[key] = value;
    }

    return params;
  };

  // Customizable Area End
}
