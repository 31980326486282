import React from "react";
import { withStyles, styled } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { AddAppointmentFormProps } from "./addAppointmentFormProps";
import { configJSON } from "../../DashboardController.web";
import { Close } from "@material-ui/icons";
import { IConsultant } from "../../interfaces";
import { InlineWidget } from "react-calendly";

const StyledSelectInputWrapper = styled(Box)({
  width: "200px",
  marginLeft: "32px"
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "12px 14px"
  }
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Source Sans Pro"
});

export const AddAppointmentForm = ({
  open,
  onClose,
  listOfConsultant,
  selectedConsultant,
  handleSelectConsultant,
  calendlyUri,
  profileDetail
}: AddAppointmentFormProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogWrapper>
        <DialogTitleWrapper>
          <AddAppointmentFormTitle>
            {configJSON.addAppointmentFormTitle}
          </AddAppointmentFormTitle>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </DialogTitleWrapper>
        <StyledSelectInputWrapper>
          <FormControl fullWidth>
            <StyledSelect
              variant="outlined"
              fullWidth
              value={selectedConsultant}
              onChange={handleSelectConsultant}
            >
              <MenuItem disabled value="">
                <em>Select Consultant</em>
              </MenuItem>
              {listOfConsultant.map((consultant: IConsultant) => (
                <StyledMenuItem value={consultant.id} key={consultant.id}>{`${
                  consultant.first_name
                } ${consultant.last_name}`}</StyledMenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </StyledSelectInputWrapper>
        {selectedConsultant !== null && (
          <InlineWidget
            url={calendlyUri}
            prefill={{
              name: profileDetail.full_name,
              email: profileDetail.email
            }}
          />
        )}
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = withStyles({
  root: {
    width: "560px",
    minHeight: "200px",
    backgroundColor: "#FFFFFF"
  }
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: "24px 32px 15px 32px"
  }
})(DialogTitle);

const AddAppointmentFormTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000"
  }
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    right: 8,
    position: "absolute"
  }
})(IconButton);
