import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

export const CustomTableHeader = ({ headers }: { headers: string[] }) => {
  return (
    <TableHead>
      <CustomTableRow>
        {headers.map((label: string) => (
          <CustomTableCell key={label}>{label}</CustomTableCell>
        ))}
      </CustomTableRow>
    </TableHead>
  );
};

const CustomTableRow = withStyles({
  root: {
    background: "#F1F2F599"
  }
})(TableRow);

const CustomTableCell = withStyles({
  root: {
    border: "none",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(TableCell);
