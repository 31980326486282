import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const ReportsIcon = () => {
  return (
    <ReportsSvgIcon style={{ color: "inherit" }}>
      <path d="M4.33293 1H18.3484C20.1826 1 21.6813 2.57579 21.6813 4.50447V13.7149C21.6813 15.6436 20.1824 17.2194 18.3484 17.2194H11.8579V20.3416H14.2344C14.9149 20.3416 14.9149 21.4297 14.2344 21.4297H8.44688C7.76617 21.4297 7.76617 20.3416 8.44688 20.3416H10.8231V17.2194H4.33293C2.49866 17.2194 1 15.6436 1 13.7149V4.50447C1 2.57579 2.49866 1 4.33293 1ZM6.04618 12.1216C5.57033 12.6303 4.83448 11.8694 5.31033 11.3607L8.56391 7.87382C8.76353 7.66025 9.09041 7.65762 9.29352 7.86752L11.661 10.4721L16.6715 6.06163C17.192 5.60353 17.855 6.43682 17.3345 6.89465L11.9537 11.6315C11.7464 11.8136 11.437 11.7976 11.2483 11.589L8.92672 9.03428L6.04618 12.1216ZM18.3484 2.08805H4.33293C3.07007 2.08805 2.03504 3.17662 2.03504 4.50447V13.7149C2.03504 15.0428 3.07007 16.1311 4.33293 16.1311H18.3484C19.6112 16.1311 20.6462 15.0428 20.6462 13.7149V4.50447C20.6462 3.17662 19.6112 2.08805 18.3484 2.08805Z" />
    </ReportsSvgIcon>
  );
};

const ReportsSvgIcon = withStyles({
  root: {
    color: "#1A1A1A"
  }
})(SvgIcon);
