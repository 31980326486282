import React, { PropsWithChildren } from "react";
import { TabPanelProps } from "./types/tabPanelProps";

export const TabPanel = ({
  currentTab,
  index,
  children
}: PropsWithChildren<TabPanelProps>) => {
  return <div hidden={currentTab !== index}>{children}</div>;
};
