import React, { ChangeEvent, Component } from "react";
import { MyProfileProps } from "./types/myProfileProps";
import { Badge } from "../../../../styledElements";
import { Tooltip } from "@material-ui/core";
import {
  FlexColumnWrapper,
  FlexColumnWrapperMedium,
  FlexColumnWrapperMediumWithErrorSpace,
  TitleBold,
  TitleLight,
  TextFieldStyled,
  ProfileWrapper,
  ProfileHeader,
  AvatarStyled,
  ButtonsWrapper,
  ResetButton,
  SaveButton,
  EditTextfieldsWrapper,
  InfoWrapper,
  Info,
  ExpertiseWrapper,
  SkillsWrapper,
  About,
  EditButton,
  UploadButtonWrapper,
  InputFile,
  UploadButton,
  TextFieldErrorTitle
} from "../../styles/styledElements";
import { configJSON } from "../../../../ConsultantDashboardController.web";
import { validateStringOnAtLeastOneChar } from "../../../../utils/validateStringOnAtLeastOneChar";

export class MyProfile extends Component<MyProfileProps> {
  state = {
    editable: false,
    name: this.props.data.name,
    email: this.props.data.email,
    about: this.props.data.about,
    expertise: this.props.data.expertise,
    skills: this.props.data.skills,
    fullNameError: ""
  };

  constructor(props: MyProfileProps) {
    super(props);

    this.setEditable = this.setEditable.bind(this);
    this.resetEditable = this.resetEditable.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveChangesHandler = this.saveChangesHandler.bind(this);
    this.onUploadPhoto = this.onUploadPhoto.bind(this);
    this.removePhotoHandler = this.removePhotoHandler.bind(this);
    this.updateProfileHandler = this.updateProfileHandler.bind(this);
  }

  componentDidUpdate(prevProps: MyProfileProps) {
    if (
      this.props.data.name !== prevProps.data.name ||
      this.props.data.email !== prevProps.data.email ||
      this.props.data.about !== prevProps.data.about ||
      this.props.data.expertise !== prevProps.data.expertise ||
      JSON.stringify(this.props.data.skills) !==
        JSON.stringify(prevProps.data.skills)
    ) {
      return {
        editable: this.state.editable,
        name: this.props.data.name,
        email: this.props.data.email,
        about: this.props.data.about,
        expertise: this.props.data.expertise,
        skills: this.props.data.skills
      };
    } else {
      return null;
    }
  }

  setEditable() {
    this.setState({ editable: true });
  }

  resetEditable() {
    this.setState({ ...this.props.data, editable: false });
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.name === "skills") {
      const skills = e.target.value.split(",");
      this.setState({ skills });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  updateProfileHandler() {
    if (this.props.updateProfile) {
      this.props.updateProfile({
        id: this.props.data.id,
        name: this.state.name,
        email: this.state.email,
        about: this.state.about,
        expertise: this.state.expertise,
        skills: this.state.skills
      });
    }
    this.setState({ editable: false });
  }

  saveChangesHandler() {
    const special = validateStringOnAtLeastOneChar(this.state.name, "special");
    const digit = validateStringOnAtLeastOneChar(this.state.name, "digit");
    const empty = this.state.name.trim() === "";

    if (special || digit || empty || this.state.name.length < 3) {
      if (special) {
        this.setState({ fullNameError: "Special characters are not allowed" });
      }

      if (empty) {
        this.setState({ fullNameError: "This field cannot be empty" });
      }

      if (this.state.name.length < 3) {
        this.setState({
          fullNameError: "Name needs to be at least 3 characters long"
        });
      }

      if (digit) {
        this.setState({ fullNameError: "Name cannot contain any digits" });
      }

      setTimeout(() => {
        this.setState({ fullNameError: "" });
      }, 3000);
    } else {
      this.updateProfileHandler();
    }
  }

  onUploadPhoto(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const files = e.target.files;

    if (this.props.uploadPhoto && files && files[0]) {
      this.props.uploadPhoto(files[0], this.props.data.id);
    }
  }

  removePhotoHandler() {
    if (this.props.removeUserPhoto) {
      this.props.removeUserPhoto(this.props.data.id);
    }
  }

  render() {
    return (
      <ProfileWrapper>
        <ProfileHeader>
          <AvatarStyled src={this.props.data.photo || ""} />
          {this.state.editable && (
            <ButtonsWrapper>
              <ResetButton variant="outlined" onClick={this.removePhotoHandler}>
                {configJSON.removeButtonTitle}
              </ResetButton>
              <UploadButtonWrapper>
                <UploadButton>
                  {configJSON.uploadButtonTitle}
                  <InputFile
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={this.onUploadPhoto}
                  />
                </UploadButton>
              </UploadButtonWrapper>
            </ButtonsWrapper>
          )}
        </ProfileHeader>
        {this.state.editable ? (
          <>
            <EditTextfieldsWrapper>
              <FlexColumnWrapperMediumWithErrorSpace>
                <TitleBold>{configJSON.nameTitle}</TitleBold>
                <TextFieldStyled
                  id="nameInput"
                  name="name"
                  variant="outlined"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  error={Boolean(this.state.fullNameError)}
                />
                {this.state.fullNameError && (
                  <TextFieldErrorTitle>
                    {this.state.fullNameError}
                  </TextFieldErrorTitle>
                )}
              </FlexColumnWrapperMediumWithErrorSpace>

              <FlexColumnWrapper>
                <TitleBold>{configJSON.aboutTitle}</TitleBold>
                <TextFieldStyled
                  name="about"
                  variant="outlined"
                  value={this.state.about}
                  multiline
                  minRows={6}
                  maxRows={6}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapper>
              {this.props.consultantSettings && (
                <FlexColumnWrapper>
                  <TitleBold>{configJSON.expertiseTitle}</TitleBold>
                  <TextFieldStyled
                    name="expertise"
                    variant="outlined"
                    value={this.state.expertise}
                    multiline
                    minRows={6}
                    maxRows={6}
                    onChange={this.handleInputChange}
                  />
                </FlexColumnWrapper>
              )}

              <FlexColumnWrapperMedium>
                <TitleBold>
                  {this.props.consultantSettings
                    ? configJSON.areasOfExpertiseTitle
                    : configJSON.areasOfInterestTitle}
                </TitleBold>
                <TextFieldStyled
                  name="skills"
                  variant="outlined"
                  value={this.state.skills.join(",")}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapperMedium>
            </EditTextfieldsWrapper>
            <ButtonsWrapper>
              <SaveButton onClick={this.saveChangesHandler} id="save-btn">
                {configJSON.saveChangesButton}
              </SaveButton>
              <ResetButton variant="outlined" onClick={this.resetEditable}>
                {configJSON.resetButtonTitle}
              </ResetButton>
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <InfoWrapper>
              <Info>
                <div>
                  <TitleBold>{this.props.data.name}</TitleBold>
                  <TitleLight>{this.props.data.position}</TitleLight>
                </div>
                <ExpertiseWrapper>
                  <TitleBold>
                    {this.props.consultantSettings
                      ? configJSON.areasOfExpertiseTitle
                      : configJSON.areasOfInterestTitle}
                  </TitleBold>
                  <SkillsWrapper>
                    {this.props.data.skills.map((skill, index) => (
                      <Tooltip key={`${index}-${skill}`} title={skill}>
                        <Badge>{skill}</Badge>
                      </Tooltip>
                    ))}
                  </SkillsWrapper>
                </ExpertiseWrapper>
              </Info>
              <About>
                <FlexColumnWrapper>
                  <TitleBold>{configJSON.aboutTitle}</TitleBold>
                  <TitleLight>{this.props.data.about}</TitleLight>
                </FlexColumnWrapper>
                {this.props.consultantSettings && (
                  <FlexColumnWrapper>
                    <TitleBold>{configJSON.expertiseTitle}</TitleBold>
                    <TitleLight>{this.props.data.expertise}</TitleLight>
                  </FlexColumnWrapper>
                )}
              </About>
            </InfoWrapper>
            <EditButton
              variant="outlined"
              onClick={this.setEditable}
              data-test-id="editBtn"
              id="edit-btn"
            >
              {configJSON.editProfileButtonTitle}
            </EditButton>
          </>
        )}
      </ProfileWrapper>
    );
  }
}
