import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  withStyles
} from "@material-ui/core";
import { AddUserFormProps } from './addUserFormProps';
import { configJSON } from "../../AdminConsoleController";
import { Close, ExpandMore } from '@material-ui/icons';
import { AddNewUserButton } from '../../styledElements';

export const AddUserForm = ({ open, onClose, inviteEmail, handleChange, sendInvite }: AddUserFormProps) => {
    return (
    <Dialog open={open} onClose={onClose} >
      <DialogWrapper>
        
        <DialogTitleWrapper>
          <AddUserFormTitle>
            {configJSON.addUserFormTitle}
          </AddUserFormTitle>
  
          <CloseButton onClick={onClose}>
            <Close/>
          </CloseButton>
        </DialogTitleWrapper>

        <DialogContentWrapper>

          <UserInputWrapper>
            <EmailInputWrapper>
              <EmailInput placeholder={configJSON.addUserFormEmailInputPlaceholder}
               value={inviteEmail}
               name="inviteEmail"
               onChange={handleChange}
               data-test-id='emailInput' />
              <UserSelect
                defaultValue='consultant'
                IconComponent={ExpandMore}
              >
                <MenuItem value='consultant'>{configJSON.addUserFormSelectConsultant}</MenuItem>
              </UserSelect>
            </EmailInputWrapper>
            <SendInviteButton onClick={sendInvite} >
            {configJSON.addUserFormSendInviteButtonText}
            </SendInviteButton>
          </UserInputWrapper>
          
        </DialogContentWrapper>
        
      </DialogWrapper>
    </Dialog>
  );
}

const DialogWrapper = withStyles({
  root: {
    width: '560px',
    height: '250px',
    backgroundColor: '#FFFFFF',
  }
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: '24px 32px',
  }
})(DialogTitle)

const DialogContentWrapper = withStyles({
  root: {
    display: 'flex',  
    flexFlow: 'column nowrap',
    gap: '22px'
  }
})(DialogContent)

const AddUserFormTitle = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: '20px',
    color: '#000000'
  }
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    right: 8,
    position: 'absolute'
  }
})(IconButton);

const EmailInput = withStyles({
  root: {
    padding: '0px 12px',
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    flexGrow: 1,
  }
})(InputBase);

const EmailInputWrapper = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingRight: '12px',
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
    "& .MuiInput-underline:before": {
      border: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    },
    '& .MuiSelect-select:focus': {
      background: '#FFFFFF',
    }
  }
})(Box);

const UserInputWrapper = withStyles({
  root: {
    display: 'flex',
    gap: '14px',
  }
})(Box);

const UserSelect = withStyles({
  root: {
    background: '#FFFFFF',
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: '14px',
    color: '#5E5E5E',
    padding: '0px 12px',
  }

})(Select);

const SendInviteButton = withStyles({
  root: {
    fontWeight: 600,
  }
})(AddNewUserButton)
