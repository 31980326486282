import React from "react";
import { Table } from "../Table";
import { Cell, TableDataTextWrap } from "../Table/tableElements";
import { TableRow, Tooltip } from "@material-ui/core";
import { Appointment } from "../../types/appointment";
import { columnTitles } from "./constants/columnTitles";
import { AvatarWithNameWrapper } from "../AvatarWithNameWrapper";
import {
  Badge,
  BadgeGroupWrapper,
  RemoveButton,
  AcceptButton
} from "../../styledElements";
import { configJSON } from "../../ConsultantDashboardController.web";
import { AppointmentsSectionProps } from "./types/appointmentsSectionProps";
import { Loader } from "../Loader";

export const AppointmentsSection = ({
  data,
  updateOnClick,
  isLoading = false,
  actionType = "cancel",
  currentPage,
  totalPages,
  handlePageChange
}: AppointmentsSectionProps) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Table<Appointment>
      handlePageChange={handlePageChange}
      totalPages={totalPages}
      currentPage={currentPage}
      rowsPerPage={10}
      columnTitles={
        actionType === "cancel"
          ? columnTitles
          : [
              ...columnTitles.filter(
                (_, index) => index !== columnTitles.length - 1
              ),
              ""
            ]
      }
      bodyItems={data || []}
      renderBodyItems={({ id, name, skills, company, date, duration }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>
              <Tooltip title={name}>
                <TableDataTextWrap>{name}</TableDataTextWrap>
              </Tooltip>
            </AvatarWithNameWrapper>
          </Cell>

          <Cell>
            <Tooltip title={skills.join(", ").trim()}>
              <BadgeGroupWrapper>
                {skills.map(
                  (skill, index) =>
                    (index === 0 || index === 1) && (
                      <Badge key={`${skill}-${index}`}>{skill}</Badge>
                    )
                )}
              </BadgeGroupWrapper>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={company}>
              <TableDataTextWrap>{company}</TableDataTextWrap>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={date}>
              <TableDataTextWrap>{date}</TableDataTextWrap>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={duration}>
              <TableDataTextWrap>{duration}</TableDataTextWrap>
            </Tooltip>
          </Cell>
          {actionType === "cancel" && (
            <Cell>
              <RemoveButton
                onClick={() => {
                  if (updateOnClick) {
                    updateOnClick(id, { status: "cancel" });
                  }
                }}
              >
                {configJSON.cancelButtonText}
              </RemoveButton>
            </Cell>
          )}
          {actionType === "acceptOrReject" && (
            <Cell>
              <AcceptButton
                onClick={() => {
                  if (updateOnClick) {
                    updateOnClick(id, { status: "accepted" });
                  }
                }}
              >
                {configJSON.acceptButtonText}
              </AcceptButton>
              <RemoveButton
                onClick={() => {
                  if (updateOnClick) {
                    updateOnClick(id, { status: "rejected" });
                  }
                }}
              >
                {configJSON.rejectButtonText}
              </RemoveButton>
            </Cell>
          )}
        </TableRow>
      )}
    />
  );
};
