import { styled, withStyles } from "@material-ui/core/styles";
import { InputBase, Avatar } from "@material-ui/core";

export const Wrapper = styled("div")({
  padding: "3px 40px",
  display: "flex",
  alignItems: "center"
});

export const Logo = styled("img")({
  width: "86px",
  height: "43px"
});

export const SearchWrapper = styled("div")({
  color: "#000000",
  display: "flex",
  alignItems: "center",
  marginLeft: "176px",
  gridGap: "22px"
});

export const SearchIcon = styled("img")({
  color: "#2596BE"
});

export const SearchInput = withStyles({
  root: {
    fontFamily: "Source Sans Pro",

    "& .MuiInputBase-input": {
      padding: 0,
      height: "20px"
    }
  }
})(InputBase);

export const AvatarWrapper = styled("div")({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
  gridGap: "30px"
});

export const AvatarWithAction = styled("div")({
  display: "flex",
  alignItems: "center",
  gridGap: "10px"
});

export const AvatarStyled = withStyles({
  root: {
    width: "50px",
    height: "50px"
  }
})(Avatar);

export const IconWrapper = styled("div")({
  color: "#000000"
});
