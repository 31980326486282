import { configJSON } from '../../../AdminConsoleController';

export const columnTitles = [
  configJSON.consultantsTableColumnName,
  configJSON.consultantsTableColumnEmail,
  configJSON.consultantsTableColumnCompany,
  configJSON.consultantsTableColumnConsultant,
  configJSON.consultantsTableColumnDate,
  configJSON.consultantsTableColumnAppointments,
  configJSON.consultantsTableColumnAction,
];