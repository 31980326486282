import React, { Component } from "react";
import { ButtonText } from "../../styledElements";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { AppointmentsIcon } from "../icons/AppointmentsIcon";
import { ButtonWithDropdownProps } from "./types/buttonWithDropdownProps";
import { ButtonWithDropdownState } from "./types/buttonWithDropdropdownState";
import {
  AccordionWrapper,
  AccordionStyled,
  AccordionSummaryStyled,
  MenuButtonStyled,
  AccordionDetailsStyled,
  AccordionDetailsWrapper,
  AccordionDetailsButtonWrapper
} from "./styles/styledElements";

export class ButtonWithDropDown extends Component<
  ButtonWithDropdownProps,
  ButtonWithDropdownState
> {
  constructor(props: ButtonWithDropdownProps) {
    super(props);
    this.state = {
      isOpened: !this.props.openable
    };

    this.toggleOpenAccordion = this.toggleOpenAccordion.bind(this);
  }

  toggleOpenAccordion() {
    this.setState({ isOpened: !this.state.isOpened });
  }

  render() {
    const arrowIcon = this.state.isOpened ? <ExpandMore /> : <ChevronRight />;

    return (
      <AccordionWrapper>
        <AccordionStyled
          expanded={this.state.isOpened}
          onChange={this.props.openable ? this.toggleOpenAccordion : undefined}
        >
          <AccordionSummaryStyled>
            <MenuButtonStyled
              id={this.props.id}
              startIcon={<AppointmentsIcon />}
              endIcon={this.props.openable ? arrowIcon : undefined}
              style={
                this.props.active
                  ? {
                      fontWeight: 700,
                      backgroundColor: "#2596BE1A",
                      color: "#2596BE"
                    }
                  : {}
              }
              onClick={this.props.onClick ? this.props.onClick : undefined}
            >
              <ButtonText data-text={this.props.title}>
                {this.props.title}
              </ButtonText>
            </MenuButtonStyled>
          </AccordionSummaryStyled>

          <AccordionDetailsStyled>
            <AccordionDetailsWrapper>
              <AccordionDetailsButtonWrapper>
                {this.props.accordionDetails}
              </AccordionDetailsButtonWrapper>
            </AccordionDetailsWrapper>
          </AccordionDetailsStyled>
        </AccordionStyled>
      </AccordionWrapper>
    );
  }
}
