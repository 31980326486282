import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

const TermsAndConditionIcon = () => {
  return (
    <TermsAndConditionSvgIcon style={{ color: "inherit" }} viewBox="0 0 15 15">
      <path d="M7.06854 3.39029C7.52592 3.31962 7.99461 3.37362 8.42333 3.54685C8.85215 3.72012 9.22542 4.00648 9.50067 4.37607C9.77604 4.7458 9.94225 5.18405 9.97923 5.64254C10.0162 6.10102 9.9224 6.55996 9.70911 6.96819C9.496 7.37631 9.17234 7.71704 8.77571 7.95415C8.67054 8.01698 8.55625 8.07594 8.46423 8.12342C8.43781 8.13709 8.41323 8.14979 8.39119 8.16132C8.27858 8.22052 8.20542 8.26379 8.15125 8.30636C8.10232 8.34473 8.08127 8.37329 8.06915 8.39706C8.0575 8.42007 8.03863 8.46923 8.03863 8.57077V9.29394C8.03863 9.48513 7.88361 9.64009 7.69248 9.64009H7.23094C7.03975 9.64009 6.88479 9.48513 6.88479 9.29394V8.57077C6.88479 8.31588 6.93458 8.08286 7.0405 7.87436C7.14608 7.66656 7.29163 7.51425 7.43869 7.39881C7.58038 7.28752 7.7324 7.20404 7.85459 7.13988C7.89227 7.12009 7.92677 7.10227 7.95896 7.08565C8.04296 7.04227 8.11219 7.00644 8.18361 6.96375C8.39984 6.83452 8.57327 6.65065 8.6864 6.43402C8.79948 6.2175 8.84852 5.97582 8.82913 5.73531C8.80969 5.49473 8.7224 5.26281 8.57529 5.06528C8.42806 4.8676 8.22631 4.71172 7.99104 4.61666C7.75571 4.52156 7.49725 4.49159 7.24473 4.53061C6.99221 4.56963 6.75717 4.6758 6.56517 4.83576C6.4645 4.91962 6.37784 5.01647 6.30706 5.12286C6.20119 5.28202 6.00844 5.38396 5.82706 5.32356L5.38917 5.17769C5.20779 5.11728 5.10763 4.91977 5.19296 4.74869C5.34561 4.44271 5.56121 4.17041 5.82659 3.94928C6.18117 3.65385 6.61109 3.46097 7.06854 3.39029Z" />
      <path d="M6.9873 10.8902C6.9873 10.7309 7.11648 10.6017 7.27577 10.6017H7.64938C7.80867 10.6017 7.93784 10.7309 7.93784 10.8902V11.2638C7.93784 11.4231 7.80867 11.5523 7.64938 11.5523H7.27577C7.11648 11.5523 6.9873 11.4231 6.9873 11.2638V10.8902Z" />
      <path d="M7.5 0C3.35787 0 0 3.35787 0 7.5C0 11.6421 3.35787 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35787 11.6421 0 7.5 0ZM1.15385 7.5C1.15385 3.99512 3.99512 1.15385 7.5 1.15385C11.0049 1.15385 13.8462 3.99512 13.8462 7.5C13.8462 11.0049 11.0049 13.8462 7.5 13.8462C3.99512 13.8462 1.15385 11.0049 1.15385 7.5Z" />
    </TermsAndConditionSvgIcon>
  );
};

const TermsAndConditionSvgIcon = withStyles({
  root: {
    color: "#1A1A1A"
  }
})(SvgIcon);

export default TermsAndConditionIcon;
