import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import { configJSON } from "../DashboardController.web";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { AddAppointmentButton } from "../styledElements";

interface ILogoutProps {
  logout: () => void;
  handleClose: () => void;
  open: boolean;
}

export const LogoutPopUp = (props: ILogoutProps) => (
  <Dialog open={props.open} onClose={props.handleClose}>
    <DialogWrapper>
      <DialogTitleWrapper>
        <FormTitle>{configJSON.cancelAppointmentFormTitle}</FormTitle>
        <FormSubTitle>{configJSON.LogoutFormSubTitle}</FormSubTitle>
        <CloseButton onClick={props.handleClose}>
          <Close />
        </CloseButton>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <LogoutButton onClick={props.logout}>Logout</LogoutButton>
        <ClosePopUpButton onClick={props.handleClose}>Cancel</ClosePopUpButton>
      </DialogContentWrapper>
    </DialogWrapper>
  </Dialog>
);

const DialogWrapper = withStyles({
  root: {
    width: "560px",
    backgroundColor: "#FFFFFF"
  }
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: "24px 32px 15px 32px"
  }
})(DialogTitle);

const DialogContentWrapper = withStyles({
  root: {
    marginLeft: "32px",
    marginRight: "32px",
    marginBottom: "1rem"
  }
})(Box);

const FormTitle = withStyles({
  root: {
    fontWeight: 600,
    color: "#000000",
    fontSize: "20px",
    fontFamily: "Source Sans Pro"
  }
})(Typography);

const FormSubTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000"
  }
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    position: "absolute",
    right: 8
  }
})(IconButton);

const LogoutButton = withStyles({
  root: {
    textTransform: "none",
    letterSpacing: "normal",
    fontFamily: "Source Sans Pro",
    color: "#FFFFFF",
    marginLeft: "auto",
    alignItems: "center",
    padding: "11px 36px",
    fontWeight: 400,
    marginRight: "15px",
    fontSize: "16px",
    backgroundColor: "rgba(37, 150, 190, 1)",
    "&:hover": {
      backgroundColor: "rgba(37, 150, 190, .8)"
    }
  }
})(Button);

const ClosePopUpButton = withStyles({
  root: {
    fontWeight: 600,
    color: "#2596BE",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E0E0E0",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  }
})(AddAppointmentButton);
