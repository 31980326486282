import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  loginPassword: string;
  loginSuccessfull: boolean;
  isEmailValid: boolean | null;
  showPassword: boolean;
  limitReached : boolean;
  invalidPassword: boolean,
  loginErrorMsg:string,
  isLoginError: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  getCodeIDReferral: string = "";
  loginApiId: string = "";
  subScribedMsg: string[];
  signInRequestId:string = "";
  userProfileRequestId:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [getName(MessageEnum.AccoutLoginSuccess),getName(MessageEnum.RestAPIResponceMessage),getName(MessageEnum.NavigationPayLoadMessage),getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),getName(MessageEnum.AuthTokenDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),getName(MessageEnum.RestAPIResponceErrorMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      loginPassword: "",
      isEmailValid: null,
      showPassword: false,
      loginSuccessfull: false,
      limitReached: false,
      invalidPassword: false,
      loginErrorMsg: "",
      isLoginError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch(apiRequestCallId){
          case this.signInRequestId:
            if (responseJson.errors) {
              this.setState({loginSuccessfull:false})
              this.setState({isLoginError:true, loginErrorMsg: responseJson.errors[0].failed_login})
            }
            else if (responseJson.meta) {
              this.setState({loginSuccessfull:true})
              window.localStorage.setItem("web_token",responseJson.meta.token)
              this.getUserProfileDetials()
              window.localStorage.setItem("web_refresh_token",responseJson.meta.refresh_token)
              window.localStorage.setItem("auth","true")
            }
            break;

          case this.userProfileRequestId:
            if (responseJson.data){
              const userDetails = JSON.stringify({
                unique_auth_id: responseJson.data.attributes.unique_auth_id,
                first_name: responseJson.data.attributes.first_name,
                last_name: responseJson.data.attributes.last_name,
                role: responseJson.data.attributes.role.name,
              })
              window.localStorage.setItem("userDetails",userDetails)
              this.redirectUser(responseJson.data.attributes.role.name)
    
            } 
            else if(responseJson.errors){
              this.setState({loginSuccessfull:false})
              window.localStorage.setItem("web_token","")
              window.localStorage.setItem("web_refresh_token","")
              window.localStorage.setItem("userDetails","")
              window.localStorage.setItem("auth","false")
            }
            break;

          default:
            break;
        }
    }

    // Customizable Area Start
 
    // Customizable Area End
  }

  // Customizable Area Start

  // 

  validateLoginEmail = (callBackFunc?: () => void) => {
    if(/\w+/.test(this.state.email)){
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/i;
      if (emailRegex.test(this.state.email)) {
        this.setState({ isEmailValid: true },()=>{
          if (typeof callBackFunc === "function") {
            callBackFunc();
          }
        });
      } else {
        this.setState({ isEmailValid: false });
      }
    }else{
      this.setState({ isEmailValid: false });
    }
  };

  onShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  };



  onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value.trim() , loginErrorMsg:"", isLoginError:false}, () =>
      this.validateLoginEmail()
    );
  };


  onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ loginPassword: event.target.value.trim() });
  };

  onClickLoginBtn = ()=>{
    if(this.state.loginPassword.length >= 8){
      this.setState({invalidPassword:false})
    }else{
      this.setState({invalidPassword:true})
    }
    this.validateLoginEmail(this.signInRequestCall)
  }

  inputValidationClassName = (success:boolean|null):string=>{
    switch(success){
      case true:
        return "success"
      case false:
        return "fail"
      default:
        return ""
    }
}

inputBackgroundAndBorderColor= (msg:string)=>{
  switch(msg){
    case "success":{
    return { borderColor :"1px solid rgba(8, 183, 26, 0.2)",
    backgroundColor : "rgba(8, 183, 26, 0.04)"}
    }
    case "fail":{
      return {borderColor :"1px solid #FF0000",backgroundColor :"rgba(255, 0, 0, 0.02)"}
    }
    default:{
     return {borderColor : "",backgroundColor : ""}
    }
  }
}

changeInputType = (showPass:boolean):string =>{
  if(showPass){
    return "text"
  }else{
    return "password"
  }
}

errorVisibilty=(visible:boolean | null)=>{
  if(visible){
    return "visible"
  }else{
    return ""
  }

}

redirectUser = (role:string, mockWindowObj?: { location: { replace: Function } })=>{
  const redirectWindow = mockWindowObj || window;
  switch(role){
    case "admin":
      redirectWindow.location.replace(configJSON.adminDashboardPath)
      break
    case "employee":
     redirectWindow.location.replace(configJSON.employeeDashboardPath)
      break
    case "consultant":
     redirectWindow.location.replace(configJSON.consultantDashboardPath)
      break
    default: 
     redirectWindow.location.replace(configJSON.loginPath)
  }
  
}

getUserProfileDetials = async ()=>{

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token" : window.localStorage.getItem("web_token")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage),
  );
  this.userProfileRequestId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/accounts/logged_user`,
  );

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet,
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

}

  signInRequestCall = async () => {

    if(this.state.isEmailValid && this.state.loginPassword.length >= 8){
      
      const header = {
        "Content-Type": configJSON.validationApiContentType
      };

      let body = {
        "data": {
          "attributes": {
            "email": this.state.email,
            "password": this.state.loginPassword
          },
          "type": "email_account"
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.signInRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_login/logins`,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage); 
    }
  }

  async componentDidMount() {
    const auth = window.localStorage.getItem("auth")
    if(auth === "true"){
      this.getUserProfileDetials()
    }
  }

  // Functions to get the Referral code for user confirmation

  // fake login function
 

  // Customizable Area End
}
