import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { styled } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { configJSON } from "../../DashboardController.web";
import { CustomTableHeader } from "../CustomTableHeader";
import ImageWithName from "../ImageWithName";
import { PaginationStyled } from "../Table/tableElements";
import { IAppointment } from "../../interfaces";
import { CancelAppointment } from "./CancelAppointmentPopUp";

interface Props {
  appointments: IAppointment[];
  currentPage: number;
  totalPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  openForm: boolean;
  handleFormClose: () => void;
  handleOpenForm: (id: string) => void;
  cancelAppointment: () => void;
}

const StyledTableRow = styled(TableRow)({
  fontFamily: "Source Sans Pro"
});

const StyledTableCellContainerDiv = styled("div")({
  display: "flex",
  fontSize: "1rem",
  fontFamily: "Source Sans Pro"
});

const StyledExperties = styled("div")({
  fontWeight: 400,
  backgroundColor: "#2596BE1A",
  color: "#2596BE",
  fontSize: "1rem",
  margin: "0 5px",
  padding: "5px 5px",
  fontFamily: "Source Sans Pro",
  borderRadius: "5px"
});

const StyledPaginationContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "2rem"
});

export const Appointment = (props: Props) => {
  return (
    <Box>
      <TableContainer elevation={3}>
        <TableContainerBox>
          <Table>
            <CustomTableHeader
              headers={configJSON.employeeAppointmentTableHeaderArr}
            />
            <TableBody>
              {props.appointments.map((appointment: IAppointment) => (
                <StyledTableRow key={appointment.id}>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      <ImageWithName
                        name={`${
                          appointment.attributes.consultant.full_name
                        }`}
                        imgUrl={appointment.attributes.photo}
                      />
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.area_of_expertise &&
                        appointment.attributes.area_of_expertise.map(
                          (experties: string) => (
                            <StyledExperties key={experties}>
                              {experties}
                            </StyledExperties>
                          )
                        )}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.company}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.appointment_datetime}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.viewable_slot.duration}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv
                      onClick={() => props.handleOpenForm(appointment.id)}
                    >
                      <ActionWapper>Cancel</ActionWapper>
                    </StyledTableCellContainerDiv>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <StyledPaginationContainer>
            <PaginationStyled
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.currentPage}
              onChange={props.handleChange}
            />
          </StyledPaginationContainer>
        </TableContainerBox>
      </TableContainer>
      <CancelAppointment
        title={configJSON.cancelAppointmentFormTitle}
        subtitle={configJSON.cancelAppointmentFormSubTitle}
        open={props.openForm}
        handleClose={props.handleFormClose}
        cancelAppointment={props.cancelAppointment}
      />
    </Box>
  );
};

const TableContainer = withStyles({
  root: {
    padding: "2em 1.5rem",
    marginBottom: "2rem"
  }
})(Paper);

const TableContainerBox = withStyles({
  root: {
    marginTop: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(Box);

const ActionWapper = withStyles({
  root: {
    color: "#BE4D25",
    cursor: "pointer",
    fontFamily: "Source Sans Pro"
  }
})(Box);
