import React from "react";
import { AvatarWithNameWrapper } from "../AvatarWithNameWrapper";
import { RemoveButton } from "../../styledElements";
import type { Employee } from "./types/employee";
import { UserTable } from "../UserTable";
import { Cell } from "../UserTable/userTableElements";
import { TableRow } from "@material-ui/core";
import { columnTitles } from "./constants/columnTitles";
import { configJSON } from "../../AdminConsoleController";

export const EmployeesSection = ({ employeeList, click, page, change }: any) => {
  const empList = employeeList.map(
    ({
      id,
      attributes: {
        email,
        first_name,
        last_name,
        created_at,
        no_of_appointments,
        no_of_consultants,
        profile: {
          data: {
            attributes: { company },
          },
        },
      },
    }: any) => {
      const date = new Date(created_at);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      const formattedDate = `${day} ${month} ${year}`;
      return {
        id,
        email,
        first_name,
        last_name,
        formattedDate,
        company,
        no_of_appointments,
        no_of_consultants,
      };
    }
  );

  return (
    <UserTable<Employee>
      rowsPerPage={10}
      columnTitles={columnTitles}
      bodyItems={empList}
      renderBodyItems={({
        id,
        first_name,
        last_name,
        email,
        formattedDate,
        company,
        no_of_appointments,
        no_of_consultants,
      }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>
              {first_name + " " + last_name}
            </AvatarWithNameWrapper>
          </Cell>
          <Cell>{email}</Cell>
          <Cell>{company}</Cell>
          <Cell>{no_of_appointments}</Cell>
          <Cell>{formattedDate}</Cell>
          <Cell>{no_of_consultants}</Cell>
          <Cell>
            <RemoveButton
              onClick={() => {
                click(id);
              }}
            >
              {configJSON.removeAccessButtonText}
            </RemoveButton>
          </Cell>
        </TableRow>
      )}
      page={page}
      change={change}
    />
  );
};
