import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const ConsultantsIcon = () => {
  return (
    <ConsultantsSvgIcon style={{color: 'inherit'}}>
      <path d="M14.5536 12C14.5156 12 14.4776 11.9931 14.4403 11.9801C14.3045 11.9319 14.2143 11.802 14.2143 11.6562V9.25H13.0837C12.8964 9.25 12.7444 9.096 12.7444 8.90625C12.7444 8.7165 12.8964 8.5625 13.0837 8.5625H14.5536C14.7409 8.5625 14.8929 8.7165 14.8929 8.90625V10.6883L16.4956 8.68969C16.5601 8.60856 16.6571 8.5625 16.7589 8.5625H19.9821C20.1688 8.5625 20.3214 8.40781 20.3214 8.21875V2.03125C20.3214 1.84219 20.1688 1.6875 19.9821 1.6875H3.01786C2.83125 1.6875 2.67857 1.84219 2.67857 2.03125V8.21875C2.67857 8.40781 2.83125 8.5625 3.01786 8.5625H3.20792C3.39521 8.5625 3.54721 8.7165 3.54721 8.90625C3.54721 9.096 3.39521 9.25 3.20792 9.25H3.01786C2.45668 9.25 2 8.78731 2 8.21875V2.03125C2 1.46269 2.45668 1 3.01786 1H19.9821C20.5433 1 21 1.46269 21 2.03125V8.21875C21 8.78731 20.5433 9.25 19.9821 9.25H16.9204L14.8162 11.8728C14.751 11.9553 14.6533 12 14.5536 12Z" />
      <path d="M13.3644 13.083L10.0267 11.9802L9.81994 10.4082C9.65242 10.479 9.45827 10.5203 9.23608 10.5203C9.19683 10.5203 9.16389 10.5123 9.12744 10.5097L9.35804 12.2686C9.37416 12.3948 9.46458 12.5016 9.59074 12.5423L13.1339 13.7145C13.7647 13.9241 14.3177 14.6671 14.3177 15.3039V18.3031C14.3177 18.4874 14.4747 18.6369 14.6681 18.6369C14.8616 18.6369 15.0186 18.4874 15.0186 18.3031V15.3032C15.0186 14.3827 14.2763 13.3867 13.3644 13.083Z" />
      <path d="M1.35046 18.6363C1.157 18.6363 1 18.4868 1 18.3026V15.3033C1 14.3828 1.74226 13.3868 2.65345 13.0831L5.99119 11.9877L6.03535 11.6459C6.05918 11.463 6.23791 11.3355 6.42716 11.3562C6.6185 11.3789 6.75518 11.5458 6.73135 11.7294L6.65916 12.2767C6.64304 12.4029 6.55192 12.509 6.42575 12.5504L2.88335 13.7133C2.25393 13.9242 1.70091 14.6672 1.70091 15.3033V18.3026C1.70091 18.4875 1.54391 18.6363 1.35046 18.6363Z" />
      <path d="M8.00941 22.0001C7.95124 22.0001 7.89236 21.9861 7.83979 21.9581L3.28387 19.561C3.17172 19.5015 3.10233 19.3894 3.10303 19.2672L3.11635 17.0337C3.11775 16.8501 3.27686 16.6712 3.46891 16.7026C3.66236 16.7032 3.81866 16.8541 3.81726 17.0377L3.80535 19.073L8.01572 21.2885L12.2149 19.2459V17.0343C12.2149 16.8501 12.3719 16.7006 12.5653 16.7006C12.7588 16.7006 12.9158 16.8501 12.9158 17.0343V19.4501C12.9158 19.5756 12.8422 19.6905 12.7251 19.7472L8.16922 21.9627C8.11875 21.9881 8.06408 22.0001 8.00941 22.0001Z" />
      <path d="M8.009 5.95767C7.34804 5.95767 6.83147 5.84285 6.51536 5.74606C6.33031 5.68932 6.22938 5.50107 6.28826 5.32618C6.34784 5.15062 6.54409 5.05449 6.72913 5.1099C6.99828 5.19267 7.43775 5.29013 8.009 5.29013C9.27624 5.29013 9.95332 4.99241 9.96033 4.98907C10.1342 4.91231 10.3437 4.97973 10.4271 5.14661C10.5098 5.31216 10.4384 5.51042 10.2645 5.58985C10.2323 5.60588 9.45217 5.95767 8.009 5.95767Z" />
      <path d="M8.009 11.298C6.06117 11.298 5.07078 9.73331 5.06447 6.64596C5.06307 5.86228 5.15068 4.73481 5.92379 3.99719C6.41723 3.52591 7.11814 3.2876 8.009 3.2876C8.89986 3.2876 9.60077 3.52591 10.0942 3.99719C10.868 4.73481 10.9556 5.86295 10.9542 6.64663C10.9472 9.73331 9.95683 11.298 8.009 11.298ZM8.009 3.95513C7.30178 3.95513 6.7824 4.12335 6.42003 4.46847C5.97706 4.89035 5.76328 5.60328 5.76538 6.64463C5.77099 9.32678 6.50414 10.6305 8.009 10.6305C9.51385 10.6305 10.247 9.32678 10.2526 6.64463C10.2547 5.60261 10.0402 4.89035 9.59726 4.4678C9.23559 4.12335 8.71622 3.95513 8.009 3.95513Z" />
      <path d="M16.0696 6.34039H13.266C13.0725 6.34039 12.9155 6.19086 12.9155 6.00662C12.9155 5.82238 13.0725 5.67285 13.266 5.67285H16.0696C16.2631 5.67285 16.4201 5.82238 16.4201 6.00662C16.4201 6.19086 16.2631 6.34039 16.0696 6.34039Z" />
      <path d="M18.625 4.3377H13.216C13.0502 4.3377 12.9155 4.18817 12.9155 4.00393C12.9155 3.81969 13.0502 3.67017 13.216 3.67017H18.625C18.7909 3.67017 18.9255 3.81969 18.9255 4.00393C18.9255 4.18817 18.7909 4.3377 18.625 4.3377Z" />
    </ConsultantsSvgIcon>
  );
}

const ConsultantsSvgIcon = withStyles({
  root: {
    color: "#1A1A1A",
    strokeWidth: 0.6,
    stroke: 'currentColor',
  },
})(SvgIcon);