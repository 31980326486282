import React, { Component } from "react";
import { ConsultantAppBarProps } from "./types/consultantAppBarProps";
import { AppBar, IconButton } from "@material-ui/core";
import {
  Wrapper,
  Logo,
  SearchWrapper,
  SearchIcon,
  SearchInput,
  AvatarWrapper,
  AvatarWithAction,
  AvatarStyled
} from "./styledElements";
import { searchIcon, logoIcon } from "../../assets";
import { configJSON } from "../../ConsultantDashboardController.web";

export class ConsultantAppBar extends Component<ConsultantAppBarProps> {
  constructor(props: ConsultantAppBarProps) {
    super(props);
  }

  render() {
    return (
      <AppBar>
        <Wrapper>
          <Logo src={logoIcon} />
          {!this.props.hideSearch && (
            <SearchWrapper>
              <SearchIcon src={searchIcon} />
              <SearchInput
                placeholder={configJSON.searchPlaceholder}
                value={this.props.searchValue}
                onChange={this.props.onChange}
              />
            </SearchWrapper>
          )}
          <AvatarWrapper>
            <AvatarWithAction>
              <IconButton onClick={this.props.settingsOnClick}>
                <AvatarStyled src={this.props.avatarUrl} />
              </IconButton>
            </AvatarWithAction>
          </AvatarWrapper>
        </Wrapper>
      </AppBar>
    );
  }
}
