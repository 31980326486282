import React from "react";
import { CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress disableShrink color="inherit" />
    </LoaderWrapper>
  );
};

export const LoaderWrapper = styled("div")({
  color: "#2596BE",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "300px"
});
