import React from "react";

import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  OutlinedInput,
  styled,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {ThemeProvider } from "@material-ui/core/styles";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { bg_image, logo_white } from "./assets";
import { Link } from "react-router-dom";
import BasicLayout from "../../forgot-password/src/Components/BasicLayout"
import {StyledButton, StyledErrorLabel,StyledIconButton,StyledInputContainer} from "../../forgot-password/src/Components/CommonStyledComponent"
import {theme} from "../../forgot-password/src/Components/theme"
import {webStyle} from "../../forgot-password/src/Components/webStyles"

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#2596BE",
  margin: "0 5px"
});

// Customizable Area End

import EmailAccountRegistrationController, {
  Props, configJSON
} from "./EmailAccountRegistrationController.web";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  StyledOutlinedInput = styled(OutlinedInput)(
    (theme: { className: string }) => {
      
      const {borderColor,backgroundColor} =  this.inputBoderAndBackgroundColor(theme.className)

      return {
      backgroundColor: "#ffff",
      zIndex: 2,
      "& input": {
        width: "550px"
      },
      "& fieldset": {
        border:borderColor,
        background:backgroundColor
      }
    }}
  );

  StyledOutlinedPhoneInput = styled(OutlinedInput)(
    (theme: { className: string }) => {
      
      const {borderColor,backgroundColor} =  this.inputBoderAndBackgroundColor(theme.className)

      return {
      backgroundColor: "#ffff",
      "& input": {
        width: "529px",
        paddingLeft: "5px"
      },
      "& fieldset": {
        border:borderColor,
        background:backgroundColor
      }
    }}
  );
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <BasicLayout>
       <Box style={webStyle.containerBoxSection2}>
                {!this.state.signUpSuccessful && (
                  <>
                    <Typography variant="h6">
                      Welcome to Pause & Ponder
                    </Typography>
                    <Typography variant="subtitle2" style={webStyle.subTitle}>
                      Create a new account here
                    </Typography>
                    <Box style={webStyle.formSection}>
                      <StyledInputContainer>
                        <Typography variant="subtitle1">Email</Typography>
                        <FormControl variant="outlined">
                          <this.StyledOutlinedInput
                            className={this.inputValidationClassName(this.state.isEmailValid)}
                            type="text"
                            name="email"
                            placeholder="example@mail.com"
                            disabled = {this.state.consultantSignUp}
                            endAdornment={
                              <InputAdornment position="end">
                                <StyledIconButton
                                  disabled
                                  className={
                                    this.state.isEmailValid === null
                                      ? "hidden"
                                      : ""
                                  }
                                >
                                  {this.state.isEmailValid && (
                                    <CheckCircleOutlineOutlined
                                      style={webStyle.successIcon}
                                    />
                                  )}
                                  {!this.state.isEmailValid && (
                                    <InfoOutlinedIcon
                                      style={webStyle.errorIcon}
                                    />
                                  )}
                                </StyledIconButton>
                              </InputAdornment>
                            }
                            onChange={this.onEmailInputChange}
                            onBlur={this.validateEmail}
                            value={this.state.email}
                            data-testid="emailInput"
                          />
                        </FormControl>
                        <div style={webStyle.error}>
                          <StyledErrorLabel
                            variant="caption"
                            className={this.errorVisibiltyClass(this.state.isEmailValid === false)}
                          >
                            Please enter a valid email address.
                          </StyledErrorLabel>
                        </div>
                      </StyledInputContainer>
                      
                      <StyledInputContainer>
                        <Typography variant="subtitle1">Phone</Typography>
                        <FormControl variant="outlined">
                          <this.StyledOutlinedPhoneInput
                            className={this.inputValidationClassName(this.state.isPhoneValid)}
                            name="phone"
                            type="text"
                            placeholder="(xxx) xxx xxxx"
                            endAdornment={
                              <InputAdornment position="end">
                                <StyledIconButton
                                  disabled
                                  className={
                                    this.state.isPhoneValid === null
                                      ? "hidden"
                                      : ""
                                  }
                                >
                                  {this.state.isPhoneValid && (
                                    <CheckCircleOutlineOutlined
                                      style={webStyle.successIcon}
                                    />
                                  )}
                                  {!this.state.isPhoneValid && (
                                    <InfoOutlinedIcon
                                      style={webStyle.errorIcon}
                                    />
                                  )}
                                </StyledIconButton>
                              </InputAdornment>
                            }
                            startAdornment = "+1"
                            onChange={this.onPhoneInputChange}
                            onBlur={this.validatePhone}
                            value={this.state.phone}
                            data-testid="phoneInput"
                          />
                        </FormControl>
                        <div style={webStyle.error}>
                          <StyledErrorLabel
                            variant="caption"
                            className={this.errorVisibiltyClass(this.state.isPhoneValid === false)}
                          >
                            Please enter a valid phone number example +1 (xxx)
                            xxx xxxx.
                          </StyledErrorLabel>
                        </div>
                      </StyledInputContainer>
                      <StyledInputContainer>
                        <Typography variant="subtitle1">
                          Password
                          <span>
                            <Tooltip
                              title={
                                <>
                                  <div>
                                    * Password should be 8-15 character in
                                    length.
                                  </div>
                                  <div>
                                    * Password should be contain atlease 1
                                    uppercase letter.
                                  </div>
                                  <div>
                                    * Password should be contain atlease 1
                                    lowercase letter.
                                  </div>
                                  <div>
                                    * Password should be contain atlease 1
                                    digit.
                                  </div>
                                  <div>
                                    * Password should be contain atlease 1
                                    special character.
                                  </div>
                                </>
                              }
                              arrow
                              placement="right-end"
                            >
                              <IconButton style={webStyle.info}>
                                <InfoOutlinedIcon style={webStyle.infoIcon}/>
                              </IconButton>
                            </Tooltip>
                          </span>
                        </Typography>
                        <FormControl variant="outlined">
                          <this.StyledOutlinedInput
                            className={this.inputValidationClassName(this.state.isPasswordValid)}
                            name="password"
                            type={this.inputTypeText(this.state.showPassword)}
                            placeholder="Enter your password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  data-testid="passwordShowBtn"
                                  onClick={this.onShowPassword}
                                >
                                  {this.state.showPassword && (
                                    <VisibilityOffIcon />
                                  )}
                                  {!this.state.showPassword && (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={this.onPasswordInputChange}
                            onBlur={this.validatePassword}
                            value={this.state.signUpPassword}
                            data-testid="passwordInput"
                          />
                        </FormControl>
                        <div style={webStyle.error}>
                          <StyledErrorLabel
                            variant="caption"
                            className={this.errorVisibiltyClass(this.state.isPasswordValid === false)}
                          >
                            Please enter a valid password.
                          </StyledErrorLabel>
                        </div>
                      </StyledInputContainer>
                      <StyledInputContainer>
                        <Typography variant="subtitle1">
                          Confirm Password
                        </Typography>
                        <FormControl variant="outlined">
                          <this.StyledOutlinedInput
                            className={this.inputValidationClassName(this.state.isConfirmPasswordMatch)}
                            name="confirm password"
                            type={this.inputTypeText(this.state.showConfirmPassword)}
                            placeholder="Enter your password"
                            endAdornment={
                              <InputAdornment data-testid="passwoedTestInput" position="end">
                                <IconButton
                                  data-testid="confirmPasswordShowBtn"
                                  onClick={this.onShowConfirmPassword}
                                >
                                  {this.state.showConfirmPassword && (
                                    <VisibilityOffIcon />
                                  )}
                                  {!this.state.showConfirmPassword && (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={this.onConfirmPasswordInputChange}
                            onBlur={() => this.matchPassword()}
                            value={this.state.signUpConfirmPassword}
                            data-testid="confirmPasswordInput"
                          />
                        </FormControl>
                        <div style={webStyle.error}>
                          <StyledErrorLabel
                            variant="caption"
                            className={this.errorVisibiltyClass(this.state.isConfirmPasswordMatch === false)}
                          >
                            Password does not match.
                          </StyledErrorLabel>
                        </div>
                      </StyledInputContainer>
                     
                      <StyledButton
                        data-testid="signupBtn"
                        variant="contained"
                        onClick={this.onSignUpSubmit}
                      >
                        Sign Up
                      </StyledButton>
                      <div style={{...webStyle.error, ...webStyle.marginBottom}}>
                          <StyledErrorLabel
                            variant="caption"
                            className={this.errorVisibiltyClass(this.state.signUpError)}>
                            Account already created or email is not valid.
                          </StyledErrorLabel>
                        </div>
                      <StyledInputContainer>
                        Have an account?
                        <span>
                          <StyledLink to={configJSON.loginPath}>Sign In</StyledLink>
                        </span>
                      </StyledInputContainer>
                    </Box>
                  </>
                )}
                {this.state.signUpSuccessful && (
                  <>
                    <Typography variant="h6" data-testid="submissionSuccess">Thanks for submission!</Typography>
                    { !this.state.consultantSignUp &&
                      <Typography variant="subtitle2" style={webStyle.subTitle}>
                        Thanks for creating the account.You can login in to account after team approve your account.
                      </Typography>
                    }
                    <div style={webStyle.buttonContainer}>
                      <Link to={configJSON.loginPath} style={webStyle.link}>
                        <StyledButton variant="contained">
                          Sign In
                        </StyledButton>
                      </Link>
                    </div>
                  </>
                )}
              </Box>
       </BasicLayout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
